import { Container } from "@mui/system";
import ContactHero from "../components/contact/ContactHero";
import Layout from "../components/layout";
import LocationMap from "../components/maps/map";
import SEO from "../components/seo";

const ContactPage = (props) => {
    const {
        slug,
        backgroundImage,
        locale,
        localizations,
        contact,
        seoBasic,
        openingsTijden,
        openTijden,
        title
    } = props.pageContext;

    return (
        <Layout language={locale} localizations={localizations}>
            <SEO title={seoBasic.seoTitle} description={seoBasic.seoMetaDescription} follow={true} index={true} />
            <ContactHero contact={contact} title={title} />
            <Container>
                <h2 style={{ textAlign: 'center', padding: '1rem 0' }}>{openingsTijden}</h2>
                {openTijden.map((openDetails) => (
                    <div className="kenmerk__text">
                        <div className="kenmerk__text-left">{openDetails.dag}</div>
                        <div className="kenmerk__text-right">{openDetails.tijd}</div>
                    </div>
                ))}
            </Container>
            <section>
                <LocationMap />
            </section>
        </Layout>
    )
}

export default ContactPage