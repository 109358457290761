import axiosInstance from './instance';
export default async function postCustomer(
  reservationReference: string,
  bookingReference: string,
  body: any
) {
  const url = `/api/v2/reservations/${reservationReference}/rentals/${bookingReference}/customer`;
  try {
    const response = await axiosInstance.post(url, body, {
      headers: {
        clientid: 420488,
        channelid: 5087,
      },
    });
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.error(error);
  }
}
