import * as React from "react"
import { useEffect, useState } from "react";
import PropTypes from "prop-types"
import "../styles/styles.scss"
import "normalize.css"
import Navbar from "./navigation/navbar"
import FooterNav from "./navigation/Footer"
import AskForVat from "./settings/vat"
import UpArrow from "../images/icons/chevron-up.png"


const Layout = ({ children, language, localizations }) => {
  const [showButton, setShowButton] = useState(false);
  if (language === undefined) {
    language = 'nl-NL'
  }
  const [locale, setLocale] = useState(language);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 300) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    });
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth' // for smoothly scrolling
    });
  };


  return (
    <React.Fragment>
      <Navbar locale={locale} setLocale={setLocale} localizations={localizations} ></Navbar>
      <main locale={locale} >{children}</main>
      <AskForVat locale={locale} />
      <FooterNav locale={locale} ></FooterNav>
      {showButton && (
        <button onClick={scrollToTop} className="back-to-top">
          <img src={UpArrow} alt="^" width={18} height={18} />
        </button>
      )}
    </React.Fragment>
  )
}


export default Layout
