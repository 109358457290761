import Logo from '../../../images/logo-clean.png'
import { Link } from "gatsby"

const Step = ({ step, active, beforeActive, isLast }) => (
    <div className="reservation-header__step">
        {
            beforeActive
                ? <span>{step.title}</span>
                : <span className={active ? 'bold' : ''}>{step.title}</span>
        }
        {!isLast && <div>{">"}</div>}
    </div>
);

const RESERVATION_STEPS = [
    { title: 'Selecteer' },
    { title: `Extra's` },
    { title: 'Bevestigen' },
];

const ReservationHeader = ({ activeStepIndex }) => {
    const steps = RESERVATION_STEPS;
    return (
        <div className="reservation-header">
            <div className="reservation-header__left">
                <Link to='/'>
                    <img src={Logo} alt="logo EBUSJE" className="navbar-logo" width={130} />
                </Link>
            </div>
            <div className="reservation-header__right">
                <div className="bottom-steps">
                    <div className={`inner step${activeStepIndex}`}></div>
                </div>
                <div className="reservation-header__steps">
                    {steps.map((step, index) =>
                        <Step
                            step={step}
                            beforeActive={index < (activeStepIndex - 1)}
                            active={index === activeStepIndex - 1}
                            isLast={index === steps.length - 1}
                            key={index}
                        />)
                    }
                </div>
            </div>
        </div>
    )
}

export default ReservationHeader