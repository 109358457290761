import { Container } from "@mui/system";
import Electric from '../../images/icons/electric.png';
import RealTime from '../../images/icons/realtime.png';
import Radius from '../../images/icons/radius.png';
import { UnsubscriptionError } from "rxjs";

const Usps = ({ usps, uspsTitle } ) => {
    return (
        <section>
            <Container>
                <div className="usps">
                    <h2>{uspsTitle}</h2>
                    <div className="usps__wrapper">
                        {usps.map((item, i) => (
                            <Usp image={item.image.url} text={item.body.data.body} key={item.strapi_id} />
                        ))}
                    </div>
                </div>
            </Container>
        </section>
    )
}

const Usp = ({ image, text, key }) => (
    <div className="usp__wrapper" key={key}>
        <div className="usp__image">
            <img src={image} alt='usp' style={{ width: 64 }}></img>
        </div>
        <div style={{ fontWeight: 600 }}>{text}</div>
    </div>
)

export default Usps;