import { Container } from "@mui/system";

{/*TODO ?? : localize heading via CMS */}

const Tags = ({ tags, locale }) => {

    return (
        <Container>
            <div>
                <h3 className="ebusje__heading">{locale!=="nl-NL" ? "Characteristics" : "Kenmerken"}</h3>
                {tags?.map((tag) =>
                    <div key={tag.strapi_id}>
                        <div className="kenmerk__text">
                            <div className="kenmerk__text-left">{tag.label}</div>
                            <div className="kenmerk__text-right"> {tag.information}</div>
                        </div>
                    </div>
                )}
            </div>
        </Container>
    )
}

export default Tags;