import React, { useState } from "react";
//import Categories from "./categories";
import DateTimeRangePicker from "./datetimerangepicker";
//import LocationSelect from "./location-select";
import Settings from '../../components/settings.json';
import { Container } from "@mui/system";

const CtaForm = (props) => {

    const [showDateInPastWarning, setShowDateInPastWarning] = useState(false);
    const [showThirtyDaysWarning, setShowThirtyDaysWarning] = useState(false);
    const [showCloseByPickupWarning, setShowCloseByPickupWarning] = useState(false);

    const navigateToMaps = () => {
        if (typeof window !== "undefined") {
            window.open('https://goo.gl/maps/Xr9gqS3nYLKd2mPx5', '_blank')
        }
    }

    const today = new Date();
    let tomorrow = new Date();
    tomorrow.setDate(today.getDate() + 1);
    tomorrow.setHours(Settings.dayStartHour);
    tomorrow.setMinutes(0);
    let endOfTomorrow = new Date();
    endOfTomorrow.setDate(today.getDate() + 1);
    endOfTomorrow.setHours(Settings.dayEndHour);
    endOfTomorrow.setMinutes(0);

    let startRent = {};
    let endRent = {};
    let diffMinutes = 0;

    //copy, if available, previous entered field and convert it to correct date format
    if (props.pickupDate && props.pickupTime) {
        const ddmmyyyy = props.pickupDate.split('-');
        const hhmm = props.pickupTime.split(':')
        startRent = new Date(ddmmyyyy[1] + "/" + ddmmyyyy[0] + "/" + ddmmyyyy[2]);
        startRent.setHours(hhmm[0]);
        startRent.setMinutes(hhmm[1]);
        //startRent = new Date(ddmmyyyy[1] + "/" + ddmmyyyy[0] + "/" + ddmmyyyy[2] + "T" + hhmm[0] + "/" + hhmm[1] + "/00");
        //startRent = new Date(`${mm}-${dd}-${yyyy}`);
        // same action for return date
        if (props.returnDate && props.returnTime) {
            const ddmmyyyy = props.returnDate.split('-');
            const hhmm = props.returnTime.split(':')
            endRent = new Date(ddmmyyyy[1] + "/" + ddmmyyyy[0] + "/" + ddmmyyyy[2]);
            endRent.setHours(hhmm[0]);
            endRent.setMinutes(hhmm[1]);
        } else {
            endRent = endOfTomorrow;
        }
    } else {
        //console.log('new Date().getHours()', new Date().getHours(), 'props pickup en reour', props.pickupDate , props.pickupTime)
        if ((new Date().getHours()) >= Settings.dayEndHour - Math.round(Settings.leadTime / 60)) {
            startRent = new Date();
            startRent.setDate(today.getDate() + 1);
            startRent.setHours(Settings.dayStartHour);
            startRent.setMinutes(Settings.dayStartMinute);
            endRent = endOfTomorrow
        } else {
            startRent = new Date();
            startRent.setDate(today.getDate());
            startRent.setHours(today.getHours() + Math.ceil((startRent.getMinutes() + Settings.leadTime) / 60));
            startRent.setMinutes(startRent.getMinutes());
            endRent = new Date(startRent);
            endRent.setHours(endRent.getHours() + Settings.minTimeRange)
            if (endRent.getHours() < Settings.dayStartHour) {
                endRent.setHours(Settings.dayStartHour)
                endRent.setMinutes(Settings.dayStartMinute)

            }
            diffMinutes = Math.round(Math.abs(startRent - endRent) / 1000 / 60 - 0.99);
            if (diffMinutes / 60 < (Settings.dayStartHour + 24 - Settings.dayEndHour + Settings.minTimeRange) &&
                endRent.getHours() < (Settings.dayStartHour + Settings.minTimeRange)) {
                endRent.setHours(Settings.dayStartHour + 24 - Settings.dayEndHour + Settings.minTimeRange - 24 + startRent.getHours());
                endRent.setMinutes(Settings.dayStartMinute)
            }
        }
    }

    const [startDate, setStartDate] = useState(startRent);
    const [endDate, setEndDate] = useState(endRent);

    const parsedStartDate = startDate.toLocaleString("nl-NL")
    const parsedEndDate = endDate.toLocaleString("nl-NL")

    const vehicleClassId = props.vehicleClassId || '';

    return (
        <form action={`/huren/voertuig-kiezen`} method="get" className="sticky-top">

            {showDateInPastWarning &&
                <div className="cta-form__warning-wrapper" >
                    <div>
                        <p className="cta-form__warning2"> Er is een tijd in het verleden geselecteerd of de retourtijd werd gelijk of kwam voor de ophaaltijd: De tijd is aangepast naar een andere gelegenheid.</p>
                    </div>
                </div>
            }
            {showThirtyDaysWarning &&
                <div className="cta-form__warning-wrapper" >
                    <div>
                        <p className="cta-form__warning2">Voor huurperiodes vanaf 30 dagen maken wij graag een offerte op maat. Neem contact met ons op via <a href="tel:+0356852226">035-685 2226</a></p>
                        <p className="cta-form__warning2">Kortere huurperiodes kunnen gespecificeerd worden door de datum(s) aan te passen.</p>
                    </div>
                </div>

            }
            {showCloseByPickupWarning &&
                <div className="cta-form__warning-wrapper" >
                    <div>
                        <p className="cta-form__warning2">De leadtime om uw auto gereed te krijgen is waarschijnlijk te kort.</p>
                        <p className="cta-form__warning2">Neem contact met ons op via <a href="tel:+0356852226">+035-6852226</a></p>
                    </div>
                </div>

            }
            <input type="hidden" name="ophalen" value={parsedStartDate}></input>
            <input type="hidden" name="retour" value={parsedEndDate}></input>
            <input type="hidden" name="vehicleClassId" value={vehicleClassId}></input>

            <div className="cta-form__wrapper">
                <Container>
                    {/* {props.includeCategories !== false &&
                    <Categories category={props.category}></Categories>
                } */}

                    <div className="cta-form__grid">
                        {props.locale !== "nl-NL" ?
                            <div className="cta-form__input-en" onClick={navigateToMaps}>
                                <input type='text' value='Hilversum, Liebergerweg 18' disabled></input>
                            </div>
                            :
                            <div className="cta-form__input" onClick={navigateToMaps}>
                                <input type='text' value='Hilversum, Liebergerweg 18' disabled></input>
                            </div>
                        }
                        <div className="cta-form__search-wrapper-datetimepicker" name="ophalenretour">

                            {/* <div className="cta-form__search-wrapper-header-datetimepicker">

                                <span className="cta-datetimerange-input">
                                    Ophalen
                                </span>
                                <span className="cta-datetimerange-input">Retour

                                </span>
            </div> */}


                            <DateTimeRangePicker
                                today={today}
                                startDate={startDate}
                                setStartDate={setStartDate}
                                endDate={endDate}
                                setEndDate={setEndDate}
                                setShowDateInPastWarning={setShowDateInPastWarning}
                                setShowThirtyDaysWarning={setShowThirtyDaysWarning}
                                setShowCloseByPickupWarning={setShowCloseByPickupWarning}
                                locale={props.locale}
                            >
                            </DateTimeRangePicker>



                        </div>
                        {props.locale === "nl-NL" ?
                            <div className="cta-form__button" name="toonbeschikbaarheid">
                                <input
                                    type="submit"
                                    value="Toon Beschikbaarheid"
                                    className="button primary-button-cta"
                                    disabled={showThirtyDaysWarning}
                                />
                            </div>
                            :
                            <div className="cta-form__button" name="showavailability">
                                <input
                                    type="submit"
                                    value="Show Availability"
                                    className="button primary-button-cta"
                                    disabled={showThirtyDaysWarning}
                                />
                            </div>
                        }
                    </div>
                </Container>

            </div>
        </form>
    )
}


export default CtaForm