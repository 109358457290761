import React, { useState } from 'react';
import {
  PaymentElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';

export default function CheckoutForm({
  reservationReference,
  bookingReference,
  customer,
  clientSecret,
}) {
  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    if (!clientSecret) {
      return;
    }

    setLoading(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `${process.env.GATSBY_STRIPE_REDIRECT_BASE_URL}/huren/bevestiging?reservationReference=${reservationReference}&clientSecret=${clientSecret}`,
      },
    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error.type === 'card_error' || error.type === 'validation_error') {
      setMessage(error.message);
    } else {
      setMessage('An unexpected error occurred.');
    }

    setLoading(false);
  };

  let options = {};
  //   TODO : Populate from customer details
  if (customer?.firstName) {
    options = {
      defaultValues: {
        billingDetails: {
          name: `${customer.firstName} ${customer.lastName}`,
          email: customer.email,
        },
      },
    };
  }

  return (
    <form id="payment-form" onSubmit={handleSubmit} style={{ padding: 24 }}>
      <PaymentElement id="payment-element" options={options} />
      <button
        disabled={loading || !stripe || !elements}
        id="submit"
        style={{ marginTop: 24 }}
        className='primary-button'
      >
        <span id="button-text" >
          {loading ? <div className="spinner" id="spinner"></div> : 'Betalen'}
        </span>
      </button>
    <p style={{ fontSize: 12, color: 'gray' }}>U betaalt borg (EUR 350) + huurbedrag</p>

      {/* Show any error or success messages */}
      {message && <div id="payment-message">{message}</div>}
    </form>
  );
}
