import React from 'react';
import CtaForm from '../components/cta-form/form';
import Layout from '../components/layout';
import OurMission from '../components/mission/mission';
import HighlightedVans from '../components/products/highlighted';
import SEO from '../components/seo';
import Usps from '../components/usps/usps';
import Hero from '../components/hero';
import DeliveryComponent from '../components/delivery/Delivery';

import { useStaticQuery, graphql } from "gatsby";

export function Index() { 

  const data = useStaticQuery(graphql`
  query {
    strapiHomepage(locale: {eq: "nl-NL"}) {
      title
      strapi_id
      locale
      introduction {
        data {
          introduction
        }
      }     
      ebusjeElectrisch {
        body {
          data {
            body
          }
        }
        image {
          alternativeText
          caption
          strapi_id
          url
        }
      }
      usps {
        body {
          data {
            body
          }
        }
        image {
          alternativeText
          caption
          url
          strapi_id
        }
      }
      mission {
        body {
          data {
            body
          }
        }
        image {
          alternativeText
          caption
          strapi_id
          url
        }
      }
      localizations {
        data {
          attributes {
            locale
          }
        }
      }
      inventory {
        title
        body {
          data {
            body
          }
        }
        image {
          alternativeText
          caption
          strapi_id
          url
        }
        buttonText
        buttonLink
      }
      uspsTitle
      subTitleMain
      subTitleTablet
      subTitleTabletImage {
        alternativeText
        caption
        url
        strapi_id
      }
      seoBasic {
        seoTitle
        seoMetadescription
      }
    }
  }
`)

  const {
    title,
    locale,
    introduction,
    ebusjeElectrisch,
    usps,
    mission,
    inventory,
    localizations,
    uspsTitle,
    subTitleMain,
    subTitleTablet,
    subTitleTabletImage,
    seoBasic
  } = data.strapiHomepage

  return (
    <Layout language='nl-NL' localizations={localizations.data}>
      <SEO title={seoBasic.seoTitle} description={seoBasic.seoMetadescription}  follow={true} index={true} />
      <Hero 
        title={title} 
        introduction= {introduction.data} 
        subTitleMain ={subTitleMain}
        subTitleTablet={subTitleTablet} 
        subTitleTabletImage = {subTitleTabletImage} 
        ebusjeElectrisch={ebusjeElectrisch.image} >
      </Hero>
      <CtaForm locale={locale}> </CtaForm>
      <Usps usps={usps} uspsTitle={uspsTitle} />
      <OurMission mission={mission} />
      {/* TODO: Add deliverycomponent */}
      <DeliveryComponent />
      <HighlightedVans inventory={inventory} />
      {/* <ImageImpressions /> */}
      {/* <FaqsHomepage /> */}
    </Layout>
  );
}

export default Index;
