import { useEffect, useState } from 'react';
import { Rentvisie } from '@bakkie/ratality';
import ReservationSelectUpsell from '../../components/reservation/step-2';
import ReservationHeader from '../../components/reservation/shared/reservation-header';
import { Container } from '@mui/system';
import SEO from '../../components/seo';
import Layout from '../../components/layout';
import AskForVat from '../../components/settings/vat';

const AccessoiresKiezen = (props) => {
  const [rental, setRental] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(undefined);
  const query = new URLSearchParams(props.location.search);
  const id = query.get('id');

  useEffect(() => {
    const rentalId = id;

    Rentvisie.getRental(rentalId)
      .then(setRental)
      .catch(setError)
      .finally(() => setLoading(false));
  }, []);
  if (loading) {
    return (
      <Container>
        <ReservationHeader activeStepIndex={2} />
        <div className="reservation__title">
          <h2>Gekozen Voertuig</h2>
        </div>
        <div className="reservation-select-wrapper">
          <div className="reservation-select-left">
            Gegevens ophalen, moment geduld!
          </div>
          {/* <div className="reservation-select-right">
            <Contact />
          </div> */}
        </div>
      </Container>
    );
  }
  if (error) {
    return (
      <Layout>
        <Container>
          <h2>Er is iets misgegaan</h2>
          <a href="/">Terug naar home</a>
        </Container>
      </Layout>
    );
  }

  return (
    <div>
      {/* <pre>{JSON.stringify(rental,null,4)}</pre> */}
      <SEO
        title="Huur uw voertuig bij eBusje"
        description="Voordelig en snel geregeld bij eBusje Autoverhuur"
        follow={true}
        index={false}
      ></SEO>
      <ReservationSelectUpsell rental={rental} id={id} />
      <AskForVat />
    </div>
  );
};

export default AccessoiresKiezen;
