import { Container } from '@mui/system';
import CtaForm from '../components/cta-form/form';
import Layout from '../components/layout';

import GetVehicleClasses from '../components/products/vehicles';
import GetVehicleClassesCMS from '../components/products/vehicles-cms';

{
  /*TODO ?? : The old version to retrieve data via rentvisie does still exist. It is commented out. Now data is retrieved via CMS */
}
const propsCtaForm = {};
const Wagenpark = (props) => {
  const { locale, localizations, descriptionMarkup, seoBasic, vehicleClass } =
    props.pageContext;

  return (
    <Layout language={locale} localizations={localizations}>
      <CtaForm props={propsCtaForm} locale={locale}></CtaForm>
      <section>
        <Container>
          {/*
                        <GetVehicleClasses locale={locale} localizations={localizations}
                         descriptionMarkup={descriptionMarkup} vehicleClass={vehicleClass} >
                     </GetVehicleClasses>
                             <h1 style={{ textAlign: 'center' }}> Wagenpark e-Busje.nl </h1> 
                    */}
          <GetVehicleClassesCMS
            locale={locale}
            localizations={localizations}
            descriptionMarkup={descriptionMarkup}
            vehicleClass={vehicleClass}
          ></GetVehicleClassesCMS>
        </Container>
      </section>
    </Layout>
  );
};

export default Wagenpark;
