import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import { IconButton } from '@mui/material';
import { Elements } from '@stripe/react-stripe-js';
import {
  Appearance,
  StripeElementsOptions,
  loadStripe,
} from '@stripe/stripe-js';
import CheckoutForm from './checkout-page';
import axios from 'axios';

interface PopupProps {
  open: boolean;
  setOpen: (value: boolean) => void;
  reservationReference: string;
  bookingReference: string;
  customer: any;
}

const stripePromise = loadStripe(process.env.GATSBY_STRIPE_PUBLISHABLE_KEY);

export default function PaymentPopup({
  open,
  setOpen,
  reservationReference,
  bookingReference,
  customer,
}: PopupProps) {
  const handleClose = () => {
    setOpen(false);
  };

  const [clientSecret, setClientSecret] = React.useState('');

  React.useEffect(() => {
    // Create PaymentIntent as soon as the page loads, but customer is present
    const getPaymentIntent = async () => {
      const body = {
        reservationReference,
        bookingReference,
        customer,
      };
      const response = await axios.post(
        `${process.env.GATSBY_RENTVISIE_API_URL}/api/v2/clients/ebusje/create-payment-intent`,
        body,
        {
          headers: { 'Content-Type': 'application/json' },
        }
      );

      setClientSecret(response.data.clientSecret);
    };

    getPaymentIntent();
  }, []);

  const appearance: Appearance = {
    theme: 'stripe',
    variables: {
      colorPrimary: '#026633',
      colorBackground: '#ffffff',
      colorText: '#30313d',
      colorDanger: 'blue',
      fontFamily: 'Ideal Sans, system-ui, sans-serif',
      spacingUnit: '4px',
      borderRadius: '4px',
      // See all possible variables below
    },
  };
  const options: StripeElementsOptions = {
    clientSecret,
    appearance,
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
        Betalen met iDEAL
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
        }}
      >
        X
      </IconButton>
      <DialogContent dividers>
        {clientSecret && (
          <Elements options={options} stripe={stripePromise}>
            <CheckoutForm
              reservationReference={reservationReference}
              bookingReference={bookingReference}
              customer={customer}
              clientSecret={clientSecret}
            />
          </Elements>
        )}
      </DialogContent>
    </Dialog>
  );
}
