import { useState, useEffect } from 'react';
import { Rental, Rentvisie } from '@bakkie/ratality';
import ReservationHeader from './shared/reservation-header';
import { navigate } from 'gatsby';
import { ValidatorUtil } from './validator.util';
import { Container } from '@mui/system';
// import OwnRisk from '../../images/svg/own_risk.png';
// import Insurance from '../../images/svg/insurance.png';
// import Distance from '../../images/svg/distance.png';
// import Other from '../../images/svg/other.png';
// import Vehicle from '../../images/svg/vehicle.png';
import bestuurder from '../../images/accessoires/bestuurder.jpg';
import pakdeken from '../../images/accessoires/pakdeken.jpg';
import pianokar from '../../images/accessoires/pianokar.jpg';
import spanband from '../../images/accessoires/spanband.webp';
import steekwagen from '../../images/accessoires/steekwagen.jpg';
import traploper from '../../images/accessoires/traploper.jpg';
import verhuisdoos from '../../images/accessoires/verhuisdoos.jpg';
import verzekering from '../../images/accessoires/verzekering.jpg';
declare global {
  interface Window {
    dataLayer: any;
  }
}

Rentvisie.setConfig({
  baseUrl: 'https://api.rentvisie.com/api',
  client: 'ebusje',
  useGuestToken: true,
});

function getOptionPrice(
  rental: Rental,
  optionType: string,
  optionId: number
): number {
  const options = rental.vehicleClass[optionType];
  const optionPerDay = options?.find(
    (option) =>
      option.optionId == optionId && option.price.unitDescription == 'per Day'
  );
  const optionPerTrip = options?.find(
    (option) =>
      option.optionId == optionId && option.price.unitDescription !== 'per Day'
  );
  if (optionPerDay) {
    return optionPerDay.price.value * rental.days;
  } else if (optionPerTrip) {
    return optionPerTrip.price.value;
  } else {
    return 0;
  }
}

function getPrice(rental: Rental, selectedOptions: SelectableOptions): number {
  const basePrice = rental.totalPrice.value;
  const optionsPrice = Object.keys(selectedOptions)
    .map((key) =>
      selectedOptions[key]
        .map((optionId) => getOptionPrice(rental, key, optionId))
        .reduce((acc, value) => acc + value, 0)
    )
    .reduce((acc, value) => acc + value, 0);

  return basePrice + optionsPrice;
}

interface SelectableOptions {
  insuranceOptions?: number[];
  mileagePackageOptions?: number[];
  optionalOptions?: number[];
}

interface PutOptions {
  ids?: number[];
  mileagePackageOptionId?: number;
  insuranceOptionId?: number;
}
const ReservationSelectUpsell = ({ rental, id }) => {
  const [selectedOptions, setSelectedOptions] = useState<SelectableOptions>({});
  const [showLoading, setShowLoading] = useState(false);

  const images = {
    bestuurder: bestuurder,
    pakdeken: pakdeken,
    pianokar: pianokar,
    spandband: spanband,
    steekwagen: steekwagen,
    traploper: traploper,
    verhuisdoos: verhuisdoos,
    verzekering: verzekering,
  };

  let includeVat = 'no';
  if (typeof window !== 'undefined') {
    includeVat = localStorage.getItem('includeVat');
  }

  const valueChanges = (key: string, ids: number[]) => {
    const newSelectedOptions = { ...selectedOptions, [key]: ids };
    setSelectedOptions(newSelectedOptions);
  };

  const handleCheckBox = (key: string, event: any) => {
    const checked = event.target.checked;
    const value = event.target.value;
    const currentValues = selectedOptions[key] ?? [];
    if (checked) {
      valueChanges(key, [...currentValues, value]);
    } else {
      valueChanges(
        key,
        currentValues.filter((id) => id !== value)
      );
    }
  };
  const mapped = Object.entries(selectedOptions).map(
    ([key, value]) => `${key}=${value.join(',')}`
  );

  const createReservation = async (rentalId) => {
    setShowLoading(true);
    createReservationAndSetOptions(rentalId);
  };

  const createReservationAndSetOptions = async (rentalId) => {
    console.log('rentalID = ', rentalId);
    const reservation = await Rentvisie.postReservation(rentalId);
    console.log('reservation = ', reservation);
    const reservationReference = reservation.reservationReference;
    const bookingReference = reservation.rentals[0].bookingReference;

    const hasInsuranceOptions = ValidatorUtil.validateInsuranceOptions(
      selectedOptions.insuranceOptions
    );
    const hasMileageOptions = ValidatorUtil.validateMileageOptions(
      selectedOptions.mileagePackageOptions
    );
    const hasOptionalOptions = ValidatorUtil.validateOptionalOptions(
      selectedOptions.optionalOptions
    );

    const data: PutOptions = {};

    if (hasInsuranceOptions) {
      data.insuranceOptionId = Number(selectedOptions.insuranceOptions[0]);
    }
    if (hasMileageOptions) {
      data.mileagePackageOptionId = Number(
        selectedOptions.mileagePackageOptions[0]
      );
    }
    if (hasOptionalOptions) {
      data.ids = selectedOptions.optionalOptions.map((id) => Number(id));
    }

    await Rentvisie.setAllOptions(reservationReference, bookingReference, data);

    const url = `/huren/checkout?reservationReference=${reservation.reservationReference}`;
    setTimeout(() => navigate(url), 1000);
  };

  const [showButton, setShowButton] = useState(false);
  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (window.pageYOffset > 250) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    });
  }, []);

  // Separate Mileagepackageoptions => Backend updates required for robust solutions //
  const totalDays = rental.days;

  const getPriceOption = (price, type) => {
    if (type === 'PerDay') {
      return price * totalDays;
    }
    return price;
  };

  const insuranceFilter = rental.vehicleClass.optionalOptions
    ? rental.vehicleClass.optionalOptions.filter(
        (option) =>
          option.category === 'Verzekering (Overig)' &&
          !option.description.includes('Risico')
      )
    : [];
  const vehicleFilter = rental.vehicleClass.optionalOptions
    ? rental.vehicleClass.optionalOptions
        .filter((option) => option.category === 'Voertuig')
        .map((option) => {
          if (option.description.includes('bestuur')) {
            option.image = bestuurder;
          } else if (option.description.toLowerCase().includes('pakdeken')) {
            option.image = pakdeken;
          } else if (option.description.toLowerCase().includes('pianokar')) {
            option.image = pianokar;
          } else if (option.description.toLowerCase().includes('spanband')) {
            option.image = spanband;
          } else if (option.description.toLowerCase().includes('steekwagen')) {
            option.image = steekwagen;
          } else if (option.description.toLowerCase().includes('traploper')) {
            option.image = traploper;
          } else if (option.description.toLowerCase().includes('verhuisdo')) {
            option.image = verhuisdoos;
          } else if (option.description.toLowerCase().includes('verzekering')) {
            option.image = verzekering;
          }
          return option;
        })
    : [];

  console.log('vehicleFilter = ', vehicleFilter);

  const deliveryFilter = rental.vehicleClass.optionalOptions
    ? rental.vehicleClass.optionalOptions.filter(
        (option) => option.category === 'Bezorging'
      )
    : [];
  const populairFilter = rental.vehicleClass.optionalOptions
    ? rental.vehicleClass.optionalOptions.filter(
        (option) => option.category === 'Populair'
      )
    : [];
  const optionFilter = rental.vehicleClass.optionalOptions
    ? rental.vehicleClass.optionalOptions.filter(
        (option) =>
          option.category !== 'Verzekering (Overig)' &&
          option.category !== 'Voertuig' &&
          option.category !== 'Populair' &&
          option.category !== 'Bezorging'
      )
    : [];
  const compare = (a, b) => {
    if (a.price.value < b.price.value) {
      return -1;
    }
    if (a.price.value > b.price.value) {
      return 1;
    }
    return 0;
  };

  const sortName = (a, b) => {
    if (a.description < b.description) {
      return -1;
    }
    if (a.description > b.description) {
      return 1;
    }
    return 0;
  };

  return (
    <Container>
      <ReservationHeader activeStepIndex={2} />
      <div className="reservation__title">
        <h2>Personaliseer uw voertuig</h2>
        {/* <p style={{ fontWeight: 600 }}>Maak jouw reservering compleet met onze meestgekozen accessoires.</p> */}
      </div>
      {showLoading ? (
        <h3>Ogenblik geduld alstublieft.</h3>
      ) : (
        <div className="reservation-select-wrapper my-l">
          <div className="reservation-select-left">
            {rental.vehicleClass.insuranceOptions && (
              <div className="extra-wrapper">
                <div className="extra-wrapper__title">
                  <h3>Eigen Risico en Borg</h3>
                </div>
                <div
                  className="extrabox-grid"
                  onChange={(selected) =>
                    valueChanges('insuranceOptions', [selected.target.id])
                  }
                >
                  {rental.vehicleClass.insuranceOptions
                    .sort(compare)
                    .map((option) => (
                      <div key={option.optionId}>
                        <input
                          type="radio"
                          id={option.optionId}
                          name="insuranceId"
                          value={option.price.value}
                          defaultChecked={option.price.value === 0}
                        />
                        <label htmlFor={option.optionId}>
                          <div className="extrabox">
                            <h3>
                              {option.description} €{' '}
                              {includeVat === 'yes'
                                ? (
                                    (getPriceOption(
                                      option.price.value,
                                      option.price.unitType
                                    ) *
                                      100) /
                                    100
                                  )
                                    .toFixed(2)
                                    .toString()
                                    .replace('.', ',')
                                : (
                                    getPriceOption(
                                      option.price.value,
                                      option.price.unitType
                                    ) / 1.21
                                  )
                                    .toFixed(2)
                                    .toString()
                                    .replace('.', ',')}
                            </h3>
                            <p className="dark-gray">
                              Verminder je eigen risico tot €{' '}
                              {option.insuranceExcessAmount.value}
                            </p>
                          </div>
                        </label>
                      </div>
                    ))}
                </div>
              </div>
            )}
            {includeVat === 'no' && deliveryFilter.length > 0 && (
              <div className="extra-wrapper">
                <div className="extra-wrapper__title">
                  {/* <img src={Insurance} alt='Overige Verzekeringen'></img> */}
                  <h3>Bezorging</h3>
                </div>
                <div className="extrabox-grid">
                  {deliveryFilter.sort(compare).map((option) => (
                    <div key={option.optionId}>
                      <input
                        type="checkbox"
                        id={option.optionId}
                        name="optionId"
                        value={option.optionId}
                        onChange={(event) =>
                          handleCheckBox('optionalOptions', event)
                        }
                      />
                      <label htmlFor={option.optionId}>
                        <div className="extrabox">
                          <h3>
                            {option.description} (€{' '}
                            {includeVat === 'yes'
                              ? (
                                  (getPriceOption(
                                    option.price.value,
                                    option.price.unitType
                                  ) *
                                    100) /
                                  100
                                )
                                  .toFixed(2)
                                  .toString()
                                  .replace('.', ',')
                              : (
                                  getPriceOption(
                                    option.price.value,
                                    option.price.unitType
                                  ) / 1.21
                                )
                                  .toFixed(2)
                                  .toString()
                                  .replace('.', ',')}
                            )
                          </h3>
                          {/* <span className="dark-gray">{option.labelMarkup.replace(/(<([^>]+)>)/gi, "")}</span> */}
                        </div>
                      </label>
                    </div>
                  ))}
                </div>
              </div>
            )}
            {vehicleFilter.length > 0 && (
              <div className="extra-wrapper">
                <div className="extra-wrapper__title">
                  {/* <img src={Vehicle} alt='Voertuig Opties'></img> */}
                  <h3>Accessoires</h3>
                </div>
                <div className="extrabox-grid">
                  {vehicleFilter.sort(sortName).map((option) => (
                    <div key={option.optionId}>
                      <input
                        type="checkbox"
                        id={option.optionId}
                        name="optionId"
                        value={option.optionId}
                        onChange={(event) =>
                          handleCheckBox('optionalOptions', event)
                        }
                      />
                      <label htmlFor={option.optionId}>
                        <div className="extrabox extrabox__flex">
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                            }}
                          >
                            <img
                              src={option.image}
                              alt={option.description}
                              width={100}
                              height={100}
                            />
                          </div>
                          <div>
                            <h3>
                              {option.description} (€{' '}
                              {includeVat === 'yes'
                                ? (
                                    (getPriceOption(
                                      option.price.value,
                                      option.price.unitType
                                    ) *
                                      100) /
                                    100
                                  )
                                    .toFixed(2)
                                    .toString()
                                    .replace('.', ',')
                                : (
                                    getPriceOption(
                                      option.price.value,
                                      option.price.unitType
                                    ) / 1.21
                                  )
                                    .toFixed(2)
                                    .toString()
                                    .replace('.', ',')}
                              )
                            </h3>
                            <span className="dark-gray">
                              {option.labelMarkup
                                .replace(/(<([^>]+)>)/gi, '')
                                .substring(0, 100)}
                            </span>
                          </div>
                        </div>
                      </label>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
          <div className="reservation-select-right">
            <div className="reservation-contact">
              <div className="reservation-contact__photo">
                <img
                  src={rental.vehicleClass.images[0].imageUrl}
                  alt={rental.vehicleClass.images[0].fileName}
                  style={{ width: '100%', maxWidth: 374, height: 204 }}
                ></img>
              </div>
              <div className="reservation-contact__text">
                <p>
                  Ophalen: {rental.pickupDate} {rental.pickupTime} <br></br>
                  Retour: {rental.dropOffDate} {rental.dropOffTime}
                </p>

                <h3 style={{ marginBottom: 0 }}>
                  <span className="extrabox__price">
                    {/* € {rental.price.value.toFixed(2)},- */}
                    {includeVat === 'yes'
                      ? ` € ${' '} ${(
                          (getPrice(rental, selectedOptions) * 100) /
                          100
                        )
                          .toFixed(2)
                          .toString()
                          .replace('.', ',')}`
                      : ` € ${' '} ${(getPrice(rental, selectedOptions) / 1.21)
                          .toFixed(2)
                          .toString()
                          .replace('.', ',')}`}
                    {includeVat === 'yes' ? ' incl. BTW' : ' excl. BTW'}
                  </span>
                </h3>
                <p style={{ marginTop: 0, fontSize: 14 }}>
                  {includeVat === 'yes'
                    ? `€ ${rental.vehicleClass.marketingOverMileagePrice.value} / km`
                    : `€ ${(
                        rental.vehicleClass.marketingOverMileagePrice.value /
                        1.21
                      ).toFixed(2)} / km`}
                  <span style={{ marginLeft: 5, fontSize: 12 }}>
                    {rental.includedMileage &&
                    rental.includedMileage > 7 &&
                    rental.includedMileage !== 9999
                      ? rental.includedMileage
                      : '0'}{' '}
                    km vrij
                  </span>
                </p>

                <button
                  className="primary-button"
                  onClick={() => createReservation(id)}
                >
                  Volgende Stap
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {showButton && (
        <button
          className="primary-button reservation-cta"
          onClick={() => createReservation(id)}
        >
          Volgende Stap
        </button>
      )}
    </Container>
  );
};

export default ReservationSelectUpsell;
