import { Container } from "@mui/system";
import Layout from "../components/layout";
import SEO from "../components/seo";
import ReactMarkdown from "react-markdown";

const Page = (props) => {
    const {
        locale,
        localizations,
        title,
        rows,
        seoBasic
    } = props.pageContext;

    const rowsLength = rows.length;
    let rowsBefore = [];
    let rowsAfter = [];
    if (rowsLength > 2) {
        rowsBefore = rows.slice(0, 2);
        rowsAfter = rows.slice(2, rowsLength)
    } else {
        rowsBefore = rows;
    }
    return (
        <Layout language={locale} localizations={localizations}>
            <SEO title={seoBasic.seoTitle} description={seoBasic.seoMetadescription} follow={true} index={true} />
            <Container>
                <h1>{title}</h1>
                {rowsBefore && rowsBefore.map((row) => (
                    <div className='row-50' key={row.strapi_id}>
                        <div>
                            <ReactMarkdown>{row.body.data.body}</ReactMarkdown>
                        </div>
                        <div>
                            <img src={row.image.url} alt={row.image.alternativeText} title={row.image.caption} className='image-bg-secondary-light' />
                        </div>
                    </div>
                ))}

            </Container>
            <div className='contact-wrapper'>
                <Container>
                    <div className='contact-box'>
                        <h2>{locale !== "nl-NL" ? "Interested in shortlease e-Busje ?" : "e-Busje shortleasen?"} </h2>
                        <a href="tel:+0356852226" className="button secondary-button" style={{ width: 300, textAlign: 'center' }}>{locale !== "nl-NL" ? "Call us : (+31) 35 685 2226" : "Bel ons op 035-685 2226"} </a>
                    </div>
                </Container>
            </div>
            <Container>
                {rowsAfter && rowsAfter.map((row) => (
                    <div className='row-50' key={row.strapi_id}>
                        <div>
                            <ReactMarkdown>{row.body.data.body}</ReactMarkdown>
                        </div>
                        <div>
                            <img src={row.image.url} alt={row.image.alternativeText} title={row.image.caption} className='image-bg-secondary-light' />
                        </div>
                    </div>
                ))}
            </Container>

        </Layout>
    )
}

export default Page;