import { Container } from "@mui/system";
import DeliveryImage from '../../images/bezorgd-nederland.png'

const DeliveryComponent = () => {
    let includeVat = 'no';
    if (typeof window !== "undefined") {
        includeVat = localStorage.getItem('includeVat');
    }

    return (
        <Container>
            <div className="delivery-wrapper">
                <div className="delivery-content">
                    <h2>e-Busje bezorgd, ontzorgd</h2>
                    {(includeVat === 'no' || includeVat === null)
                        ? (<div>
                            <p>
                                Waar uw bedrijf ook gevestigd zit in Nederland, wij bezorgen binnen 24 uur. Het enige wat u hoeft te doen is in te stappen!
                            </p>
                        </div>)
                        : <p>Huurt u voor een periode vanaf 14 dagen? Dan bezorgen wij ook bij u in heel Nederland.</p>
                    }
                    <p>
                        Wij gaan zelfs nog een stapje verder. In een straal van 25km van Hilversum, bezorgen wij dezelfde dag nog!
                    </p>
                </div>
                <div className="delivery-image">
                    <img src={DeliveryImage} alt='bezorgd in heel NL' style={{ maxWidth: '80%' }}></img>
                </div>
            </div>
        </Container>
    )
}

export default DeliveryComponent;
