import { useState } from "react"
import { Container } from "@mui/system"
import ReservationHeader from './shared/reservation-header'
import { navigate } from "gatsby"
import CtaForm from "../cta-form/form"
import { locations } from "../settings.json";
// import QuoteForm from "../modals/quoteForm";
import Settings from '../settings.json';

const ReservationSelectVehicle = ({ availableRentals, searchArgs }) => {
    const [open, setOpen] = useState(false);

    let includeVat = 'no'
    if (typeof window !== "undefined") {
        includeVat = localStorage.getItem('includeVat');
    }


    const compare = (a, b) => {
        if (a.price.value < b.price.value) {
            return -1;
        }
        if (a.price.value > b.price.value) {
            return 1;
        }
        return 0;
    }

    let category = searchArgs.categoryId
    let pickupLocation = searchArgs.pickupLocationId
    let pickupDate = searchArgs.pickupDate
    let pickupTime = searchArgs.pickupTime
    let returnDate = searchArgs.dropOffDate
    let returnTime = searchArgs.dropOffTime

    if (availableRentals.length > 0) {
        category = availableRentals[0].vehicleClass.category.categoryId
        pickupLocation = availableRentals[0].pickupLocation;
        pickupDate = availableRentals[0].pickupDate
        pickupTime = availableRentals[0].pickupTime
        returnDate = availableRentals[0].dropOffDate
        returnTime = availableRentals[0].dropOffTime
    }

    let parseLocation = {
        "value": pickupLocation.locationId,
        "label": pickupLocation.town
    }

    if (availableRentals.length === 0) {
        parseLocation.value = searchArgs.pickupLocationId
        {
            locations.map((loc) => {
                if (loc.value === Number(parseLocation.value)) {
                    parseLocation.label = loc.label
                }
            }
            )
        }
    }

    // Check Real Time

    const toNextStep = (availableRental) => {
        navigate(`/huren/accessoires-kiezen?id=${availableRental.rentalId}`)
    }

    return (

        <Container style={{ paddingBottom: 50 }}>
            <ReservationHeader activeStepIndex={1} />
            <CtaForm
                category={category}
                location={parseLocation}
                pickupDate={pickupDate}
                pickupTime={pickupTime}
                returnDate={returnDate}
                returnTime={returnTime}
                locale={'nl-NL'}
            ></CtaForm>
            <div className="reservation__title">
                <h2>Beschikbare Voertuigen</h2>
            </div>
            <div className="reservation-select-wrapper" >
                <div>
                    {availableRentals.length === 0 &&
                        <div className="hero-form__warning">
                            <p>Zoekopdracht heeft helaas geen resultaten opgeleverd. Probeer het op een ander tijdstip of bel ons op <a href="tel:0356852226">035-685 2226</a>.                                          </p>
                            <div></div>
                        </div>
                    }
                    <div className='cards__grid'>
                        {availableRentals.sort(compare).map((availableRental) => (
                            <div className='card' key={availableRental.vehicleClass.vehicleClassId} onClick={() => toNextStep(availableRental)}>
                                <div className="card__content">
                                    <div className='card__title-wrapper'>
                                        <div className='card__title'>
                                            <h3>{
                                                availableRental.vehicleClass.labelMarkup
                                                    ? availableRental.vehicleClass.labelMarkup.replace(/(<([^>]+)>)/gi, "")
                                                    : availableRental.vehicleClass.description
                                            }</h3>
                                        </div>
                                        {availableRental.availableVehicles === 1 && <span style={{ color: 'red' }}>{availableRental.availableVehicles} Beschikbaar</span>}
                                    </div>
                                    <div className='card__image'>
                                        <img src={availableRental.vehicleClass.images[0]?.imageUrl} alt={availableRental.vehicleClass.description} style={{ maxWidth: '100%', height: 204, width: 374 }} />
                                    </div>
                                    <div className='card__features'>
                                        {availableRental.vehicleClass.tags && availableRental.vehicleClass.tags.map((tag) => (
                                            <div className='card__feature' key={tag.tagId}>
                                                {tag.tagDescription}
                                            </div>
                                        ))}
                                    </div>

                                </div>
                                <div className='card__price'>
                                    Totaal:
                                    {
                                        (includeVat === 'yes')
                                            ? ` € ${' '} ${availableRental.bookingTotal.value.toFixed(2).toString().replace(".", ",")}`
                                            : ` € ${' '} ${(availableRental.bookingTotal.value / 1.21).toFixed(2).toString().replace(".", ",")}`
                                    }
                                    {includeVat === 'yes' ? ' incl. BTW' : ' excl. BTW'}
                                </div>
                                <div className='card__more-info'>
                                    Selecteer
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </Container>
    )
}

export default ReservationSelectVehicle

