import { useState, useRef } from 'react';
import ArrowRight from '../../images/icons/chevron-right.png';
import ArrowLeft from '../../images/icons/chevron-left.png';


const ImageSlider = ({images}) => {
    const ref = useRef(null)

    const scroll = (scrollOffset) => {
        ref.current.style.scrollBehavior = "smooth";
        ref.current.scrollLeft += scrollOffset
    }
   
    return (
        <div>
            <div className="images__wrapper">
                <div className='images__overflow' ref={ref}>
                    <div className='image__image'>
                       {images.map((image) => (
                                <img src={image.url} alt={image.alternativeText} title={image.caption} style={{ maxWidth: '100%' }} />
                            )
                        )}
                    </div>
                    <button onClick={() => scroll(-360)} className='images__scroll-left-button'><img src={ArrowLeft} alt='left' width={24} /></button>
                    <button onClick={() => scroll(360)} className='images__scroll-right-button'><img src={ArrowRight} alt='right' width={24} /></button>
                </div>
            </div>
        </div>
    )
}

export default ImageSlider;