import { Container } from "@mui/system";
import { Link } from "gatsby";
import Layout from "../../components/layout";

const ThankYou = () => (
    <Layout>
        <Container style={{ padding: 24 }}>
            <h1>Bedankt voor uw reservering</h1>
            <p>Wij hebben u een bevestiging gestuurd per e-mail.</p>
            <Link to="/">Terug naar Home</Link>
        </Container>
    </Layout>
);

export default ThankYou;