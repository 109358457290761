import { Rentvisie } from '@bakkie/ratality';
import { useEffect, useState } from 'react';
import ReservationSelectVehicle from '../../components/reservation/step-1';
import ReservationHeader from '../../components/reservation/shared/reservation-header';
import { Container } from '@mui/system';
import Layout from '../../components/layout';
// import Skeletons from "../../components/loading/skeletons";
import SEO from '../../components/seo';
import { Link } from 'gatsby';
import AskForVat from '../../components/settings/vat';

const VoertuigKiezen = (props) => {
  const [availableRentals, setAvailableRentals] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(undefined);

  const query = new URLSearchParams(props.location.search);
  const categoryId = query.get('categoryId');
  const vehicleClassId = query.get('vehicleClassId');
  const location = query.get('location');

  // Parse PickupDateTime
  const pickupDateTime = query.get('ophalen') || '31-12-2022 08:00';

  const pickupDate = pickupDateTime.split(' ')[0].replace(/,+/g, '');
  const pickupTimeSplit = pickupDateTime.split(' ')[1];
  const pickupTime =
    pickupTimeSplit.split(':')[0] + ':' + pickupTimeSplit.split(':')[1];

  // Parse ReturnDateTime
  const returnDateTime = query.get('retour') || '31-12-2022 17:00';

  const returnDate = returnDateTime.split(' ')[0].replace(/,+/g, '');
  const returnTimeSplit = returnDateTime.split(' ')[1];
  const returnTime =
    returnTimeSplit.split(':')[0] + ':' + returnTimeSplit.split(':')[1];

  useEffect(() => {
    Rentvisie.setConfig({
      baseUrl: 'https://api.rentvisie.com/api',
      client: 'ebusje',
      useGuestToken: true,
    });

    const pickupLocationId = location || 7017;
    const dropOffLocationId = location || 7017;

    const filters = {
      pickupDate: pickupDate,
      pickupTime: pickupTime,
      dropOffDate: returnDate,
      dropOffTime: returnTime,
      vehicleClassId: vehicleClassId || '',
      categoryId: categoryId || '',
    };

    Rentvisie.getRentals(pickupLocationId, dropOffLocationId, filters)
      .then(setAvailableRentals)
      .catch(setError)
      .finally(() => setLoading(false));
  }, []);

  if (loading) {
    return (
      <Container>
        <ReservationHeader activeStepIndex={1} />
        <div className="reservation__title">
          <h2>Beschikbare Voertuigen</h2>
          <p>
            Wij controleren de beschikbaarheid. Ogenblik geduld alstublieft!
          </p>
        </div>
      </Container>
    );
  }

  if (error) {
    return (
      <Layout>
        <Container>
          <h2>Er is iets misgegaan</h2>
          <a href="/">Terug naar home</a>
        </Container>
      </Layout>
    );
  }

  //niet zo netjes...2 keer zelfde variablele, hoie gebruik ik filters ipv searchargs ?
  const searchArgs = {
    pickupDate: pickupDate,
    pickupTime: pickupTime,
    dropOffDate: returnDate,
    dropOffTime: returnTime,
    vehicleClassId: vehicleClassId || '',
    categoryId: categoryId || '',
    pickupLocationId: location || 7017, //7017 = Hilversum
    dropOffLocationId: location || 7017,
  };

  return (
    <div>
      <SEO
        title="Huur uw voertuig bij eBusje"
        description="Voordelig en snel geregeld bij eBusje Autoverhuur"
        follow={true}
        index={false}
      ></SEO>
      <ReservationSelectVehicle
        availableRentals={availableRentals}
        searchArgs={searchArgs}
      />
      <AskForVat />
    </div>
  );
};

export default VoertuigKiezen;
