import { Container } from "@mui/material";
import LogoKuperus from '../../images/logo-kuperus.webp';
import EbusjeLoupe from '../../images/icons/ebusje-in-loupe.webp';
import LogoBovag from '../../images/bovag-logo.jpg'
import Box from '@mui/material/Box';
import Instaicon from '../../images/icons/insta-icon.png';
import Facebookicon from '../../images/icons/facebook-icon.png';
import Linkedinicon from '../../images/icons/linkedin-icon.jpg';

const FooterNav = ({ locale }) => (
    <footer>
        <div className='footer-info__wrapper'>
            <Container className='footer-info__spacing'>
                <div className="footer-info__boxes">
                    <div>
                        {'e-'}
                        <span className="footer-info__busje">Busje</span>
                        {'.nl'}
                    </div>
                    <div>
                        <Box color="black" bgcolor="white" p={1}>
                            <div className="footer-info__label"> {(locale !== "nl-NL" ? "Telephone" : "Telefoon")} </div>
                            <div className="footer-info__highlighted"><a href='tel:+31356852226'>035-685 2226</a></div>
                        </Box>
                    </div>
                    <div>
                        <Box color="black" bgcolor="white" p={1}>
                            <div className="footer-info__label">E-mail</div>
                            <div className="footer-info__highlighted"><a href='mailto:info@e-busje.nl'>info@e-busje.nl</a></div>
                        </Box>
                    </div>
                    {/* <div>
                            <img src={Instaicon} alt='Instagram Icon'></img>
                            <img src={Linkedinicon} alt='Linkedin Icon'></img>
                            <img src={Facebookicon} alt='Facebook Icon'></img>
                        </div>
                    */}
                    <div>
                        <Box color="black" bgcolor="white" p={1}>
                            <div className="footer-info__label">{(locale !== "nl-NL" ? "Address" : "Adres")}</div>
                            <div className="footer-info__highlighted">Liebergerweg 18 - 26 <br></br> 1221 JS Hilversum{(locale !== "nl-NL" ? <br></br> : "")}{(locale !== "nl-NL" ? "the Netherlands" : "")}</div>
                        </Box>
                    </div>
                </div>
            </Container>
        </div>
        <div className='footer-bg'>
            <Container>
                <div className='footer-flex'>
                    <div>
                        <div>
                            e-Busje.nl is powered by:
                        </div>
                        <a href='https://www.kuperus.eu' target='_blank' rel='noreferrer'>
                            <div>
                                <img src={LogoKuperus} alt='Logo Kuperus'></img>
                            </div>
                        </a>
                    </div>
                    <div>
                        <div>
                            {(locale !== "nl-NL" ? "Affiliated with: " : "Aangesloten bij: ")}
                        </div>
                        <div>
                            <img src={LogoBovag} alt='Bovag'></img>
                        </div>
                    </div>
                    <div>
                        <div className='hide-loupe'>
                            <img src={EbusjeLoupe} alt='e-Busje Loupe'></img>
                        </div>
                    </div>
                </div>
            </Container>
        </div>
    </footer>
)

export default FooterNav;