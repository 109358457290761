import Layout from "../components/layout";
import SEO from "../components/seo";
import ImageSlider from "../components/products/imageslider";
import CtaForm from '../components/cta-form/form';
import Drawer from '@mui/material/Drawer';
import Tags from "../components/products/taqs";
import ProductDescription from "../components/products/description";
import { useState } from "react";
import { Container } from "@mui/system";

const Product = (props) => {
    const [ctaOpen, setCtaOpen] = useState(false);

    let includeVat = 'no'
    if (typeof window !== "undefined") {
        includeVat = localStorage.getItem('includeVat');
    }

    const {
        title,
        subFolder,
        seoBasic,
        body,
        classId,
        tags,
        heroImages,
        fromPrice,
        locale,
        localizations,
    } = props.pageContext;

    const anchor = "top";
    const handleCtaForm = () => { setCtaOpen(!ctaOpen) }

    return (
        <Layout language={locale} localizations={localizations} > 
            {/* TODO: Fix layout props issue */}
            <SEO title={seoBasic.seoTitle} description={seoBasic.seoMetadescription} follow={true} index={true} />
            <ImageSlider images={heroImages} />
            <Container>
                <div className="product__details">
                    <div className='image__bottomline'>
                        <p className="product__car-price">{title}</p>
                        <div>
                            <span className="product__car-price">
                                {
                                    (includeVat === 'yes')
                                        ? ` € ${fromPrice.toFixed(2).toString().replace(".", ",")}`
                                        : ` € ${(fromPrice / 1.21).toFixed(2).toString().replace(".", ",")}`
                                }
                            </span>
                            <span className='product__per'> {'  Per  '} {(locale!=="nl-NL"? "Day" : "Dag")} {' '} {' '} {' '} </span>

                            <Drawer
                                anchor={anchor}
                                open={ctaOpen}
                                onClose={handleCtaForm}
                            >
                                {
                                    <div className='images__background-navbar'>
                                        <div className='images__close-button' onClick={handleCtaForm}>
                                            <button className='secondary-button' >Sluit</button>
                                        </div>
                                        <Container >
                                            <div className='images__cta-navbar'>
                                                <CtaForm locale={locale}>
                                                </CtaForm>
                                            </div>
                                        </Container>
                                    </div>
                                }
                            </Drawer>
                            <button onClick={handleCtaForm} className='primary-button' style={{ margin: '10px 0' }}>{locale!=="nl-NL"? "Book" : "Reserveer"}</button>
                        </div>
                    </div>
                </div>
            </Container>
            <ProductDescription body={body} locale={locale} />
            <Tags tags={tags} locale={locale} />
            <div style={{ textAlign: 'center' }}>
                <button onClick={handleCtaForm} className='primary-button' style={{ margin: '10px 0' }}>{locale!=="nl-NL"? "Book" : "Reserveer"}</button>
            </div>
        </Layout >
    )
}

export default Product;