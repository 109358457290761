import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { Link } from 'gatsby';
import Logo from '../../images/logo-clean.png';
// import Close from '../../images/icons/close-icon-dark.png';
import IconNL from '../../images/icons/icons8-nl.png'
import IconGB from '../../images/icons/icons8-gb.png';
import CarCharger from '../../images/svg/icon_energy.png';
import { useState, useEffect } from 'react';
import { Container } from "@mui/material";
import Burger from '../../images/icons/hamburger.png';
import { useLocation } from "@reach/router";

import { useStaticQuery, graphql } from "gatsby";

const drawerWidth = 300;

const Navbar = ({ window, locale, localizations }) => {

    const data = useStaticQuery(graphql`
    query {
        allStrapiNavbar {
            nodes {
              locale
              navbar {
                id
                navbarTitle
                navbarUrl
              }
            }
        }
    }
    `)
    const navbarNodes = data.allStrapiNavbar.nodes;

    const translations = {
        navbar: [
            {
                language: navbarNodes[0].locale,
                navbarFleetTitle: navbarNodes[0].navbar[0].navbarTitle,
                navbarFleetUrl: navbarNodes[0].navbar[0].navbarTitle,
                navbarShortleaseTitle: navbarNodes[0].navbar[1].navbarTitle,
                navbarShortleaseUrl: navbarNodes[0].navbar[1].navbarUrl,
                navbarContactTitle: navbarNodes[0].navbar[2].navbarUrl,
                navbarContactUrl: navbarNodes[0].navbar[2].navbarUrl,
            },
            {
                language: navbarNodes[1].locale,
                navbarFleetTitle: navbarNodes[1].navbar[0].navbarTitle,
                navbarFleetUrl: navbarNodes[1].navbar[0].navbarTitle,
                navbarShortleaseTitle: navbarNodes[1].navbar[1].navbarTitle,
                navbarShortleaseUrl: navbarNodes[1].navbar[1].navbarUrl,
                navbarContactTitle: navbarNodes[1].navbar[2].navbarUrl,
                navbarContactUrl: navbarNodes[1].navbar[2].navbarUrl,
            },
        ]
    }

    const [mobileOpen, setMobileOpen] = useState(false);


    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    // 👇️ filter with 1 condition: will lead to the corresponding data in the zero entry of the earray.
    const navbarTextsFiltered = translations.navbar.filter(navbarText => {
        return navbarText.language === locale;
    });
    if (navbarTextsFiltered.length === 0) {
        console.log('Geen language(locale)')
    }

    
    const navbarItems = [
        {
            url: (locale !== "nl-NL" ? `${locale}/${navbarTextsFiltered[0].navbarFleetUrl}` : navbarTextsFiltered[0].navbarFleetUrl),
            linkText: navbarTextsFiltered[0].navbarFleetTitle
        },
        {
            url: (locale !== "nl-NL" ? `${locale}/${navbarTextsFiltered[0].navbarShortleaseUrl}` : navbarTextsFiltered[0].navbarShortleaseUrl),
            linkText: navbarTextsFiltered[0].navbarShortleaseTitle
        },
        {
            url: (locale !== "nl-NL" ? `${locale}/${navbarTextsFiltered[0].navbarContactUrl}` : navbarTextsFiltered[0].navbarContactUrl),
            linkText: navbarTextsFiltered[0].navbarContactTitle
        },
    ];

    const drawer = (
        <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
            <img src={Logo} alt='close menu' width={240} style={{ padding: 24 }} />
            <Divider />
            <List>
                {navbarItems.map((item, index) => (
                    <Link to={`/${item.url}`} key={index}>
                        <ListItemButton sx={{ textAlign: 'center' }}>
                            <ListItemText primary={item.linkText} />
                        </ListItemButton>
                    </Link>
                ))}
            </List>
        </Box>
    );

    const container = window !== undefined ? () => window().document.body : undefined;

    return (
        <div className='navbar-wrapper' >

            <Box sx={{ display: 'flex' }} >
                <AppBar component="nav">
                    <Container>
                        <Toolbar style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Typography component="div" sx={{ padding: "10px 0" }} >
                                {/* navigation for homepage icon */}
                                {localizations?.map((localization, index) => (
                                    (localization.attributes.locale !== 'nl-NL' ?
                                        <Link to='/' key={index}><img src={Logo} alt='RentVisie - Next Generation' width={130}></img></Link>
                                        : <Link to='/en' key={index}><img src={Logo} alt='RentVisie - Next Generation' width={130}></img></Link>
                                    )))
                                }
                            </Typography>

                            <Box sx={{ display: { xs: 'none', md: 'block' }, alignItems: 'center' }}>
                                {/* navigation for subpages */}
                                {navbarItems.map((item, index) => (
                                    <Link to={`/${item.url}`} className={`button nav-button`} activeClassName="active-nav" key={index}>
                                        {item.linkText}
                                    </Link>
                                ))}
                            </Box>
                            {/* navigation for language */}
                            {localizations?.map((localization, index) => (
                                (
                                    localization.attributes.locale === 'nl-NL'
                                        ? <Link to={localization.attributes.slug ? `/${localization.attributes.slug}` : `/`} key={index}>
                                            <nav className='navbar-nav'>
                                                <img className='icon-flag-other-language' src={IconNL} alt='flagNL' ></img>
                                                <span className='navbar-other-language'> NL
                                                <span className='navbar-separator' >{' '} | </span></span>
                                                <img className='icon-flag-language' src={IconGB} alt='flagGB' ></img>
                                                <span className='navbar-language' > EN </span>
                                            </nav>
                                        </Link>
                                        : <Link to={localization.attributes.slug ? `/en/${localization.attributes.slug}` : `/en`} key={index}>
                                            <nav className='navbar-nav'>
                                                <img className='icon-flag-language' src={IconNL} alt='flagNL' ></img>
                                                <span className='navbar-language' > NL </span>
                                                <span className='navbar-separator' > | {' '} </span>
                                                <img className='icon-flag-other-language' src={IconGB} alt='flagGB'>
                                                </img> <span className='navbar-other-language' > EN </span>
                                            </nav>
                                        </Link>
                                )
                            ))
                            }

                            {/*                             
                            <Link to={switchUrl}>
                                {locale !== "nl-NL" ?
                                    <nav> <img src={IconNL} alt='flagNL' height={20}></img> <span className='navbar-other-language'>NL  |</span> <img src={IconGB} alt='flagGB' height={20}></img> <span className='navbar-language' style={{ marginLeft: 3 }}> EN </span></nav>
                                    :
                                    <nav> <img src={IconNL} alt='flagNL' height={20}></img> <span className='navbar-language' >NL</span>  <span className='navbar-other-language' style={{ marginLeft: 3 }}> | </span> <img src={IconGB} alt='flagGB' height={20}></img> <span className='navbar-other-language' style={{ marginLeft: 3 }}> EN </span> </nav>
                                }
                            </Link> */}

                            {locale !== "nl-NL" ?
                                <span
                                    className='hide-mobile'
                                    style={{ color: '#000', fontWeight: 600, display: 'flex', alignItems: 'center' }}
                                >
                                    Rent
                                    <span
                                        className='primary-color'
                                        style={{ marginLeft: 3 }}> today ...</span>
                                    <img src={CarCharger} alt='elektrisch'
                                        style={{ width: 24, marginLeft: 5 }}></img>
                                </span>
                                :
                                <span className='hide-mobile' style={{ color: '#000', fontWeight: 600, display: 'flex', alignItems: 'center' }}>Huren <span className='primary-color' style={{ marginLeft: 3 }}> anno nu ...</span> <img src={CarCharger} alt='elektrisch' style={{ width: 24, marginLeft: 5 }}></img></span>
                            }

                            <IconButton
                                color="inherit"
                                aria-label="open drawer"
                                edge="start"
                                onClick={handleDrawerToggle}
                                sx={{ mr: 2, display: { md: 'none' } }}
                            >
                                <img src={Burger} alt='menu' />
                            </IconButton>
                        </Toolbar>
                    </Container>
                </AppBar>
                <Box component="nav">
                    <Drawer
                        container={container}
                        variant="temporary"
                        open={mobileOpen}
                        onClose={handleDrawerToggle}
                        ModalProps={{
                            keepMounted: true, // Better open performance on mobile.
                        }}
                        sx={{
                            display: { xs: 'block', sm: 'none' },
                            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                        }}
                    >
                        {drawer}
                    </Drawer>
                </Box>
            </Box>
        </div>
    );
}

export default Navbar;
