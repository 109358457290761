import { useEffect, useState } from 'react';
import { Rentvisie } from '@bakkie/ratality';
import { Link } from 'gatsby';
import ReactMarkDown from 'react-markdown';

const GetVehicleClassesCMS = ({ locale, vehicleClass }) => {
  let includeVat = 'no';
  if (typeof window !== 'undefined') {
    includeVat = localStorage.getItem('includeVat');
  }

  let localUrl = '';
  if (locale !== 'nl-NL') {
    localUrl = locale + '/';
  }

  const compareFn = (a, b) => {
    if (a.price < b.price) {
        return -1;
    }
    if (a.price > b.price) {
        return 1;
    }
    return 0;
  }

  console.log(vehicleClass)
  return (
    <div className="wagenpark-grid">
      {vehicleClass.sort(compareFn).map((wagenklasse, index) => (
        <Link
          to={`/${localUrl}${wagenklasse.buttonLink
            ?.replace(/(<([^>]+)>)/gi, '')
            .toLowerCase()
            .replaceAll(' ', '-')}`}
            key={index}
        >
          <div className="product__card">
            {wagenklasse.image && (
              <div>
                <img
                  className="product__image"
                  src={wagenklasse.image.url}
                  alt="eBusje"
                />
              </div>
            )}
            <div className="product__details">
              <div className="product__topline">
                {vehicleClass[index].tags &&
                  vehicleClass[index].tags.map((feature) => (
                    <div className="product__car-size">
                      {feature.groupCode === 'afm' && feature.tagDescription}
                      {feature.tagCode === 'Diesel' && (
                        <div
                          className={`product__fuel ${
                            feature.tagCode === 'Diesel'
                              ? 'bg-orange'
                              : 'bg-red'
                          }`}
                        >
                          {feature.tagDescription === 'Diesel'
                            ? 'Inclusief Stroom'
                            : 'Exclusief Stroom'}
                        </div>
                      )}
                    </div>
                  ))}
              </div>
              {/* TODO: uitlijnen dat window even groot is ook als ene body text groter is dan de andere */}
              <ReactMarkDown>{wagenklasse.body.data.body}</ReactMarkDown>
              {/*<p dangerouslySetInnerHTML={{ __html: wagenklasse.descriptionMarkup }}></p>*/}
              <div className="product__bottomline">
                <span className="product__car-price">
                  {includeVat === 'yes'
                    ? ` € ${wagenklasse.price
                        .toFixed(2)
                        .toString()
                        .replace('.', ',')}`
                    : ` € ${(wagenklasse.price / 1.21)
                        .toFixed(2)
                        .toString()
                        .replace('.', ',')}`}
                </span>
                <span className="product__per">
                  {' '}
                  {locale !== 'nl-NL' ? ' Per Day ' : ' Per Dag '}{' '}
                  {/*price.unitDescription*/}{' '}
                </span>

                <button className="primary-button">
                  {wagenklasse.buttonText}
                </button>
              </div>
            </div>
          </div>
        </Link>
      ))}
      {/* <div className="product__card bg-primary-light">
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%', color: 'white', fontSize: '150%', fontWeight: '600' }}>
                    {'e-'}
                    <span className="footer-info__busje">Busje</span>
                    {'.nl'}
                </div>
            </div> */}
    </div>
  );
};

export default GetVehicleClassesCMS;
