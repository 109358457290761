import React from 'react';
import DatetimeRangePicker from '../datetimerangepicker/index';
//import 'moment/locale/nl';
import Settings from '../../components/settings.json'

const DateTimeRangePicker = ({
    today,
    startDate, setStartDate,
    endDate, setEndDate,
    setShowDateInPastWarning,
    setShowThirtyDaysWarning,
    setShowCloseByPickupWarning,
    locale
}) => {

    const getDifferenceInDays = (date1, date2) => {
        const diffInMs = Math.abs(date2 - date1);
        return Math.ceil(diffInMs / (1000 * 60 * 60 * 24));
    }

    let yesterday = new Date();
    yesterday.setDate(new Date().getDate() - 1);
    let tomorrow = new Date()
    tomorrow.setDate(new Date().getDate() + 1);
    let diffMinutes = 0;
    let fromStartMin = 0;
    let beforeNow = false;

    const valid = (current) => {
        return (
            current.isAfter(yesterday) &&
            current.day() !== 0
        );
    }

    const onChange = (input) => {
        // input.start = startDate;
        // console.log ('override input start', input.start)
        let newStartDate = new Date(input.start);
        let newEndDate = new Date(input.end);
        startDate = newStartDate;  // werkt beter dan setStartDate(newStartDate)
        endDate = newEndDate;

        // gelijke datums kan je terugkrijgen uit de datetimerangepicker als je
        // een pickup date in toekomst kiest, die verder is dan de return datum.
        // Dan maakt de picker de return datum gelijk aan de pickup datum
        // Via onderstaand truc, zet je dan de pickup datum 1 dag terug en ..
        // maak je de return datum een aantal uur later.
        // Om te voorkomen dat deze logica door dit module getriggerd wordt, wordt 
        // bij een aanpassing van de return tijd altijd 1 seconde anders gemaakt dan de
        // pickup tijd
        if (
            (input.start.getDate() === input.end.getDate()) &&
            (input.start.getMonth() === input.end.getMonth()) &&
            (input.start.getYear() === input.end.getYear()) &&
            (input.start.getHours() === input.end.getHours()) &&
            (input.start.getMinutes() === input.end.getMinutes()) &&
            today.getDate() != input.start.getDate()
        ) {
            newStartDate.setDate(newStartDate.getDate() - 1);
            newStartDate.setHours(Settings.dayStartHour);
            newStartDate.setMinutes(0);
            setStartDate(newStartDate);

            newEndDate.setDate(newStartDate.getDate());
            newEndDate.setMonth(newStartDate.getMonth());

            //correcties vanwege raar gedrag setDate / setMonth functies
            if (newStartDate.getDate() === 31)  // day 31 leads in setdate function to 1st day,
            {
                newEndDate.setDate(31);        // so this is to trick the day to become 31 
                if (newStartDate.getMonth() === 11) {   //similarly in dec (month 11) the year is
                    newEndDate.setYear(1900 + newStartDate.getYear());     //set wrongly to eg 122
                }
            }
            newEndDate.setHours(newStartDate.getHours() + Settings.dayEndHour - Settings.dayStartHour);
            newEndDate.setMinutes(0);
            setEndDate(newEndDate)
        }
        else {
            if (input.start.getHours() >= Settings.dayEndHour) {
                newStartDate.setDate(startDate.getDate() + 1);
                newStartDate.setHours(Settings.dayStartHour);
                newStartDate.setMinutes(0);
                setStartDate(newStartDate);
                //console.log(startDate, newStartDate)
            } else {
                setStartDate(input.start)
                fromStartMin = Math.round(Math.abs(newStartDate - today) / 1000 / 60 - 0.99);
                beforeNow = (newStartDate - today) / 1000 / 60 - 0.99 < 0
                if (fromStartMin < Settings.leadTime || beforeNow) {
                    newStartDate.setHours(today.getHours() + Math.ceil((newStartDate.getMinutes() + Settings.leadTime) / 60));
                }
            }
            setEndDate(input.end)
        }
        //pickup datum in past check en eventueel aanpassen
        //console.log('startDate voor aanpassing in on Change', startDate);
        // kijk nog eens goed of de test voor de maand 12 of 11 moet zijn.
        if (
            (
                (startDate.getDate() === today.getDate()) &&
                (startDate.getMonth() === today.getMonth()) &&
                (startDate.getYear() === today.getYear()) &&

                (
                    (startDate.getHours() < today.getHours())
                    ||
                    (
                        (startDate.getHours() === today.getHours()) &&
                        (startDate.getMinutes() <= today.getMinutes())
                    )
                )

            // ) || (
            //     (
            //         (startDate.getDate() === 1) &&
            //         (startDate.getMonth() < today.getMonth())
            //     ) || (
            //         (startDate.getDate() === 1) &&
            //         (startDate.getMonth() === 11) &&
            //         (startDate.getMonth() === 0)
            //     )
            )
        ) {

            if (((today.getHours() >= Settings.dayEndHour - 2) && today.getMinutes() > 0)) {
                // user change after 6 PM
                //console.log('starttijd > Settings.dayEndHour uur impossible: set to next day ');
                newStartDate = tomorrow;
                newStartDate.setHours(Settings.dayStartHour);
                setStartDate(startDate)
            } else        // set leadtime for huur at 1-2 hours
            {

                fromStartMin = Math.round(Math.abs(newStartDate - today) / 1000 / 60 - 0.99);
                beforeNow = (newStartDate - today) / 1000 / 60 - 0.99 < 0
                if (fromStartMin < Settings.leadTime || beforeNow) {
                    newStartDate.setHours(today.getHours() + Math.round(Settings.leadTime / 60 + 0.99));
                    setStartDate(startDate)
                }
            }
            newStartDate.setMinutes(0);

            //console.log('verander ', newStartDate, startDate)
            setStartDate(newStartDate);
            setShowDateInPastWarning(true)
        } else {
            setShowDateInPastWarning(false)
        }
        //eventueel return datum aanpassen, als die voor startdatum is gekomen of binnen de range van 4 uur
        //of na de uiterste return tijd ???
        diffMinutes = Math.round(Math.abs(endDate - newStartDate) / 1000 / 60 - 0.99);
        if (endDate.getYear() === newStartDate.getYear()) {
            if (endDate.getMonth() === newStartDate.getMonth()) {
                if (endDate.getDate() === newStartDate.getDate() ||
                    diffMinutes < 60 * Settings.minTimeRange - 1) {
                    if (diffMinutes < 60 * Settings.minTimeRange) {
                        if (newStartDate.getHours() + Settings.minTimeRange < 24) {
                            if ((newStartDate.getHours() + Settings.minTimeRange) >= endDate.getHours()) {
                                newEndDate.setHours(newStartDate.getHours() + Settings.minTimeRange)
                                newEndDate.setMinutes(newStartDate.getMinutes())
                                newEndDate.setSeconds((newStartDate.getSeconds() + 1) % 60);
                            } else {
                                newEndDate.setHours(23);
                                newEndDate.setMinutes(0)
                                newEndDate.setSeconds((newStartDate.getSeconds() + 1) % 60);
                            }
                            setEndDate(newEndDate);
                        } else { // add one day and set start hour on start of day plus minimal hiring range 
                            newEndDate.setDate(newStartDate.getDate() + 1);
                            newEndDate.setHours((Settings.dayStartHour + 24 - Settings.dayEndHour) + Settings.minTimeRange - 24 + newStartDate.getHours());
                            newEndDate.setSeconds((newStartDate.getSeconds() + 1) % 60);
                            setEndDate(newEndDate);
                        }
                    } else {
                        if (endDate.getHours() >= Settings.dayEndHour + Settings.minTimeRange) {
                            setEndDate(newEndDate);
                        }
                    }
                } else
                    if (newEndDate.getDate() < newStartDate.getDate()) {
                        newEndDate.setDate(newStartDate.getDate());
                        newEndDate.setHours(Settings.dayEndHour);
                        newEndDate.setSeconds((newStartDate.getSeconds() + 1) % 60);
                        setEndDate(newEndDate);
                    } else {
                        diffMinutes = Math.round(Math.abs(newStartDate - newEndDate) / 1000 / 60 - 0.99);
                        if (diffMinutes / 60 < (Settings.dayStartHour + 24 - Settings.dayEndHour + Settings.minTimeRange) &&
                            newEndDate.getHours() < (Settings.dayStartHour + Settings.minTimeRange)) {
                            newEndDate.setHours(Settings.dayStartHour + 24 - Settings.dayEndHour + Settings.minTimeRange - 24 + newStartDate.getHours());
                        }
                    }
            } else
                if (newEndDate.getMonth() < newStartDate.getMonth() ||
                    ((newEndDate.getMonth() < 1) &&
                        (newStartDate.getMonth() > 1))) {
                    newEndDate.setDate(1);
                    newEndDate.setMonth(newStartDate.getMonth());
                    newEndDate.setHours(Settings.dayEndHour);
                    newEndDate.setSeconds((newStartDate.getSeconds() + 1) % 60);
                    setEndDate(newEndDate);
                }
        } else
            if (newEndDate.getYear() < newStartDate.getYear()) {
                newEndDate.setDate(1);
                newEndDate.setMonth(newStartDate.getMonth());
                newEndDate.setMonth(newStartDate.getYear());
                newEndDate.setHours(Settings.dayEndHour);
                newEndDate.setSeconds((newStartDate.getSeconds() + 1) % 60);
                setEndDate(newEndDate);
            }

        //console.log ('sets in dtrp', setShowDateInPastWarning)

        //console.log ('sets in dtrp', setShowThirtyDaysWarning)
        if ((getDifferenceInDays(endDate, startDate)) >= 30) {
            // endDate = new Date(startDate);
            // endDate.setDate(endDate.getDate()+30)
            setShowThirtyDaysWarning(true)
        } else {
            setShowThirtyDaysWarning(false)
        }

        const todayWeekDay = today.getDay();
        const startDateWeekDay = startDate.getDay();

        fromStartMin = Math.round(Math.abs(startDate - today) / 1000 / 60 - 0.99);
        beforeNow = ((startDate - today) / 1000 / 60 - 0.99) < 0;
        if (
            (fromStartMin < Settings.leadTime || beforeNow)
            ||
            (startDate.getHours <= Settings.dayStartHour && fromStartMin < Settings.dayStartHour * 60)
            ||
            (startDate.getHours() <= Settings.dayStartHour &&
                today.getHours() >= Settings.dayEndHour &&
                todayWeekDay != 6 &&
                fromStartMin < ((24 - today.getHours() - 1 + Settings.dayStartHour) * 60))
            ||
            (startDate.getHours() <= Settings.dayStartHour &&
                today.getHours() >= Settings.dayEndHour &&
                todayWeekDay === 6 &&
                fromStartMin < ((24 - today.getHours() - 1 + Settings.dayStartHour + 24) * 60))
            ||
            (startDate.getHours() <= Settings.dayStartHour &&
                todayWeekDay === 0 &&
                fromStartMin < ((Settings.dayStartHour + 24) * 60))
        ) {
            // endDate = new Date(startDate);
            // endDate.setDate(endDate.getDate()+30)
            setShowCloseByPickupWarning(true)
        } else {
            setShowCloseByPickupWarning(false)
        }
        setEndDate(endDate)
        setStartDate(startDate)
    }

    let pickerClassName = ""
    if (locale !== "nl-NL") {
        pickerClassName = "cta-datetimerange-input-en"
    } else {
        pickerClassName = "cta-datetimerange-input"
    }

    return (
        <div>

            <DatetimeRangePicker
                locale={locale}
                timeFormat="HH:mm"
                dateFormat="DD-MM-YYYY"
                onChange={onChange}
                startDate={startDate}
                endDate={endDate}
                input={false}
                readOnly={true}
                isValidStartDate={valid}
                startTimeConstraints={
                    {
                        hours: { min: Settings.dayStartHour, max: Settings.dayEndHour, step: 1 },
                        minutes: { min: 0, max: 60, step: 15 }
                    }
                }
                endTimeConstraints={
                    {
                        hours: { min: Settings.dayStartHour, max: 23 },
                        minutes: { min: 0, max: 60, step: 15 }
                    }
                }
                pickerClassName={pickerClassName}
                className='cta-datetimerange-wrapper'
                closeOnSelect={true}

            />
        </div>
    )
}

export default DateTimeRangePicker
