import { Container } from "@mui/system";
import { Link } from "gatsby";
import ReactMarkDown from 'react-markdown';

const HighlightedVans = ({ inventory }) => (
    <section style={{ marginBottom: 96 }}>
        <Container>
            <h2>{inventory.title}</h2>
            <div className="highlighted-products__wrapper">
                <div className='highlighted-products__image'>
                    <img src={inventory.image.url} alt='Alle e-Busjes.nl' />
                </div>
                <div className='highlighted-products__content'>
                    <ReactMarkDown>{inventory.body.data.body}</ReactMarkDown>
                    <div>
                        <Link to={`/${inventory.buttonLink}`} className='button primary-button'>
                            {inventory.buttonText}
                        </Link>
                    </div>
                </div>
            </div>
        </Container>
    </section>
)

export default HighlightedVans;