import Layout from "../components/layout";
import SEO from "../components/seo";

const LinkToCms = () => (
    <Layout>
        <SEO title="e-Busje.nl link naar het CMS" description={'Via deze link raadplegen jullie het CMS'} index={false} follow={false}></SEO>
        <div style={{ height: '70vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <a href='https://ebusje-cms.herokuapp.com/admin' className='primary-button' style={{padding:12}} target="_blank" rel="noreferrer">Naar CMS</a>
        </div>
    </Layout>
)


export default LinkToCms;