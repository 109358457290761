import React, { useState, useEffect } from "react";

const AskForVat = ({locale}) => {
    const [askVat, setAskVat] = useState(false);
    const [showVat, setShowVat] = useState(undefined);
    const [extendDisplay, setExtendDisplay] = useState(false);

    let includeVat = 'no'
    if (typeof window !== "undefined") {
        includeVat = localStorage.getItem('includeVat');
    }

    useEffect(() => {
        if (typeof window !== "undefined") {
            const itemStr = localStorage.getItem('includeVat')
            // if the item doesn't exist, return null
            if (!itemStr) {
                setAskVat(true)
            }
        }
    }, []);
    const setVat = async (arg) => {
        setExtendDisplay(false)
        if (arg === 'yes') {
            localStorage.setItem('includeVat', 'yes');
            setShowVat(true)
        } else {
            localStorage.setItem('includeVat', 'no');
            setShowVat(false)
        }
        if (typeof window !== "undefined") { window.location.reload() };
    }
    return (
        <div className={`company-or-individual__wrapper`}>
            {!extendDisplay &&
                <div className='company-or-individual__display' onClick={() => setExtendDisplay(true)}>
                    {
                        includeVat !== 'yes' &&
                        <div style={{ display: 'flex', alignItems: 'center', paddingRight: 5 }}>
                            <p>{locale === 'en' ? 'Excl. VAT' : 'Excl. BTW'}</p>
                        </div>
                    }
                    {
                        includeVat === 'yes' &&
                        <div style={{ display: 'flex', alignItems: 'center', paddingRight: 5 }}>
                            <p>{locale === 'en' ? 'Incl. VAT' : 'Incl. BTW'}</p>
                        </div>
                    }
                </div>
            }
            {
                extendDisplay &&
                <div className='company-or-individual__display'>
                    <div className="company-or-individual__select" onClick={() => setVat('yes')}>
                        <p>
                        {locale === 'en' ? 'Individual (Incl. VAT)' : 'Particulier (incl. BTW)'}
                        </p>
                    </div>
                    <div className="company-or-individual__select" onClick={() => setVat('no')}>
                        <p>
                        {locale === 'en' ? 'Business (excl. VAT)' : 'Zakelijk (excl. BTW)'}
                        </p>
                    </div>
                </div>
            }


        </div >
    )
}

export default AskForVat;