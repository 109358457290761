import React, { useEffect, useState } from 'react';
import Layout from '../../components/layout';
import { Elements, useStripe } from '@stripe/react-stripe-js';
import {
  Appearance,
  StripeElementsOptions,
  loadStripe,
} from '@stripe/stripe-js';
import { Container } from '@mui/material';
import { Link } from 'gatsby';

const ConfirmationPage = (props) => {
  const query = new URLSearchParams(props.location.search);
  const clientSecret = query.get('clientSecret') || '';

  const options: StripeElementsOptions = {
    clientSecret,
  };

  const stripePromise = loadStripe(process.env.GATSBY_STRIPE_PUBLISHABLE_KEY);

  if (!clientSecret) {
    return (
      <Layout>
        <h1>Wij kunnen de betaling niet ophalen.</h1>
        <Link to="/">Terug naar Home</Link>
      </Layout>
    );
  }

  return (
    <Layout>
      <Elements options={options} stripe={stripePromise}>
        <ConfirmationComponent clientSecret={clientSecret} />
      </Elements>
    </Layout>
  );
};

const ConfirmationComponent = ({ clientSecret }) => {
  const [status, setStatus] = useState('Betaling status ophalen');
  const [message, setMessage] = useState('');
  const stripe = useStripe();

  useEffect(() => {
    if (!stripe) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case 'succeeded':
          setStatus('Betaling geslaagd!');
          setMessage(
            'Wij hebben de betaling in goede orde ontvangen. Het e-Busje is voor u gereserveerd. U ontvangt een reserveringsbevestiging.'
          );
          console.log('Payment succeeded!');
          break;
        case 'processing':
          setStatus('Betaling aan het verwerken!');
          setMessage('Een ogenblik geduld alstublieft.');
          break;
        case 'requires_payment_method':
          setStatus('Betaling niet succesvol!');
          setMessage(
            'Er is iets fout gegaan tijdens de betaling. Probeert u het alstublieft opnieuw of neem telefonisch contact met ons op.'
          );
          break;
        default:
          setStatus('Betaling niet succesvol!');
          setMessage(
            'Er is iets fout gegaan tijdens de betaling. Probeert u het alstublieft opnieuw of neem telefonisch contact met ons op.'
          );
          break;
      }
    });
  }, [stripe, clientSecret]);

  return (
    <Container style={{ padding: 24, minHeight: '50vh' }}>
      <h1>{status}</h1>
      <p>{message}</p>
      <Link to="/">Terug naar Home</Link>
    </Container>
  );
};

export default ConfirmationPage;
