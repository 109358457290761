import { useEffect, useState } from "react";
import { Rentvisie } from '@bakkie/ratality';
import { Link } from "gatsby";

const GetVehicleClasses = ({locale, localizations, descriptionMarkup, vehicleClass}) => {
    const [loading, setLoading] = useState(true)
    const [vehicleClasses, setVehicleClasses] = useState<VehicleClass | undefined>(undefined)
    const [error, setError] = useState(undefined);

    let includeVat = 'no'
    if (typeof window !== "undefined") {
        includeVat = localStorage.getItem('includeVat');
    }

    useEffect(() => {
        Rentvisie.setConfig({
            baseUrl: 'https://api.rentvisie.com/api',
            client: 'ebusje',
            useGuestToken: true,
        })

        Rentvisie.getVehicleClasses()
            .then(setVehicleClasses)
            .catch(setError)
            .finally(() => setLoading(false))
    }, [])
    if (loading) {
        return (
            <p>Loading....</p>
        )
    }
    if (error) {
        return (
            <p>{error.message}</p>
        )
    }

    const compare = (a, b) => {
        if (a.marketingPrices[0].value < b.marketingPrices[0].value) {
            return -1;
        }
        if (a.marketingPrices[0].value > b.marketingPrices[0].value) {
            return 1;
        }
        return 0;
    }

    return (
        <div className="wagenpark-grid">
            {vehicleClasses.sort(compare).map((wagenklasse, index) =>
                <Link to={`/${wagenklasse.subLabelMarkup?.replace(/(<([^>]+)>)/gi, "").toLowerCase().replaceAll(" ", "-")}`}>
                    <div className='product__card'>
                        {wagenklasse.images &&
                            <div>
                                <img className="product__image" src={wagenklasse.images[0].imageUrl} alt='eBusje' />
                            </div>
                        }
                        <div className="product__details">
                            <div className="product__topline">
                                {vehicleClasses[index].tags && vehicleClasses[index].tags.map(feature => (
                                    <div className="product__car-size">
                                        {feature.groupCode === "afm" && feature.tagDescription}
                                        {feature.tagCode === "Diesel" &&
                                            <div className={`product__fuel ${feature.tagCode === 'Diesel' ? 'bg-orange' : 'bg-red'}`}>
                                                {feature.tagDescription === 'Diesel' ? 'Inclusief Stroom' : 'Exclusief Stroom'}
                                            </div>
                                        }
                                    </div>
                                ))}
                            </div>
                            <h3>{
                                wagenklasse.labelMarkup
                                ? wagenklasse.labelMarkup.replace(/(<([^>]+)>)/gi, "")
                                : wagenklasse.description
                            }</h3>
                            <p dangerouslySetInnerHTML={{ __html: wagenklasse.descriptionMarkup }}></p>
                            <div className='product__bottomline'>
                                {wagenklasse.marketingPrices && wagenklasse.marketingPrices.map(price => (
                                    price.unitType === "PerFullDay" &&
                                    <>
                                        <span className="product__car-price">
                                            {
                                                (includeVat === 'yes')
                                                    ? ` € ${price.value.toFixed(2).toString().replace(".", ",")}`
                                                    : ` € ${(price.value / 1.21).toFixed(2).toString().replace(".", ",")}`
                                            }
                                        </span>
                                        <span className='product__per'> {'  Per Dag '} {/*price.unitDescription*/} {' '} {' '} {' '} {' '} </span>
                                    </>
                                ))}
                                <button className='primary-button'>Bekijk</button>
                            </div>
                        </div>
                    </div>
                </Link>
            )
            }
            {/* <div className="product__card bg-primary-light">
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%', color: 'white', fontSize: '150%', fontWeight: '600' }}>
                    {'e-'}
                    <span className="footer-info__busje">Busje</span>
                    {'.nl'}
                </div>
            </div> */}
        </div>
    )
}

export default GetVehicleClasses