import React from 'react';
import { useState } from 'react';
import * as L from "leaflet";
import "leaflet-gesture-handling";
import "leaflet-gesture-handling/dist/leaflet-gesture-handling.css";
import { MapContainer, TileLayer, Marker, Popup, useMapEvents } from 'react-leaflet'

const position = [52.22236764946108, 5.187740342563635]
const markerPosition = [52.22236764946108, 5.187740342563635]

const LocationMap = () => {
  if (typeof window !== 'undefined') {
    return (
      <MapContainer center={position} zoom={11} gestureHandling='true' className='contact-map-container'>
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <Marker position={markerPosition}>
          <Popup>
            <h3>e-Busje.nl</h3>
            <p>Liebergerweg 18 <br></br>
            1221 JS Hilversum
            </p>
          </Popup>
        </Marker>
        <LocationMarker />
      </MapContainer>
    )
  }
  return (<p>Geen image</p>)
}

function LocationMarker() {
  const [position, setPosition] = useState(null)
  const map = useMapEvents({
    click() {
      map.locate()
    },
    locationfound(e) {
      setPosition(e.latlng)
      // map.flyTo(e.latlng, map.getZoom())
    },
  })

  return position === null ? null : (
    <Marker position={position}>
      <Popup>Je bent nu hier</Popup>
    </Marker>
  )
}


export default LocationMap