import { Container } from "@mui/system"
import ReactMarkdown from "react-markdown"

{/*TODO ?? : localize heading via CMS */}
const ProductDescription = ({ body, locale}) => (
    <Container>
        <h3 className="ebusje__heading">{locale!=="nl-NL"? "Description" : "Beschrijving"}</h3>
        <ReactMarkdown className="ebusje__description">{body.data.body}</ReactMarkdown>
    </Container>
);

export default ProductDescription;