import { Container } from '@mui/material';
import Ebusje from '../images/e-busje-sprinter.webp';
import IconEnergy from '../images/power-cord.png';
import ContactHero from './contact/Contact-tobedeleted';
import CarCharger from '../images/svg/icon_energy.png';
import ReactMarkDown from 'react-markdown';

const Hero = ({ title, introduction,
                subTitleMain, subTitleTablet, subTitleTabletImage,
                ebusjeElectrisch }) => (

    <div className="hero-background">
         <div className='hero-wrapper'>
            <div className="hero__text">
                <h1>{title}</h1>
                <h2 className='hide-mobile'>{subTitleMain}</h2>
                <h2 className='hide-tablet'>{subTitleTablet} <img src={subTitleTabletImage.url} alt='elektrisch huren'></img></h2>
                <p>
                    <ReactMarkDown>{introduction.introduction}</ReactMarkDown>
                </p>
            </div>
            <div className="hero__image">
                <img src={ebusjeElectrisch.url} alt='eBusje powered by Kuperus' style={{ borderRadius: 10 }} />
            </div>
        </div>
    </div>

)

export default Hero