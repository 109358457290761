import isEmail from 'validator/lib/isEmail';

export class ValidatorUtil {
  private static nameValidator = /^[a-z ,.'-]{2,50}$/i;
  private static vatValidator = /^[a-zA-Z , 0-9]{12,18}$/;
  private static phoneValidator = /^[+]?[0-9]{4}?[-]?[0-9]{6,9}$/;
  private static zipCodeValidator = /^(\d{4}[a-zA-Z]{2})$/;
  private static houseNumberValidator = /^(\d{1,4})$/;
  private static additionValidator = /^[a-zA-Z ,. -'\d]{1,3}/iu;
  private static streetValidator = /^[a-z0-9 ,.'-]{2,140}$/iu;
  private static driverLicenseValidator = /^\d{10}$/;
  private static driverLicenseIssueDateValidator =
    /^[0-9]{4}-[0-9]{2}-[0-9]{2}$/;
  private static driverLicenseExpirationDateValidator =
    /^[0-9]{4}-[0-9]{2}-[0-9]{2}$/;
  private static driverLicenseIssuePlaceValidator = /^[a-z ,.'-]{2,50}$/iu;
  private static placeOfBirthValidator = /^[a-z ,.'-]{2,50}$/iu;
  private static dateOfBirthValidator = /^[0-9]{4}-[0-9]{2}-[0-9]{2}$/;
  private static registrationNumberValidator = /^[0-9]{8}$/;

  static validateName(name: string): boolean {
    return ValidatorUtil.nameValidator.test(name);
  }
  static validateVatNumber(vatNumber: string): boolean {
    return ValidatorUtil.vatValidator.test(vatNumber);
  }
  static validatePhone(phoneNumber: string): boolean {
    return ValidatorUtil.phoneValidator.test(phoneNumber);
  }
  static validateZipCode(zipCode: string): boolean {
    return ValidatorUtil.zipCodeValidator.test(zipCode);
  }
  static validateEmail(email: string): boolean {
    return isEmail(email);
  }
  static validateHouseNumber(houseNumber: string): boolean {
    return ValidatorUtil.houseNumberValidator.test(houseNumber);
  }
  static validateAddition(addition: string): boolean {
    return ValidatorUtil.additionValidator.test(addition);
  }
  static validateStreet(street: string): boolean {
    return ValidatorUtil.streetValidator.test(street);
  }
  static validateDriverLicense(driverLicense: string): boolean {
    return ValidatorUtil.driverLicenseValidator.test(driverLicense);
  }
  static validateDriverLicenseIssueDate(issueDate: string): boolean {
    return ValidatorUtil.driverLicenseIssueDateValidator.test(issueDate);
  }
  static validateDriverLicenseExpirationDate(
    expirationDate: string,
    issueDate: string
  ): boolean {
    if (
      ValidatorUtil.driverLicenseExpirationDateValidator.test(expirationDate)
    ) {
      const issue = new Date(issueDate);
      const expiration = new Date(expirationDate);
      return expiration > issue && expiration > new Date();
    }
    return false;
  }
  static validateDriverLicenseIssuePlace(issuePlace: string): boolean {
    return ValidatorUtil.driverLicenseIssuePlaceValidator.test(issuePlace);
  }
  static validatePlaceOfBirth(placeOfBirth: string): boolean {
    return ValidatorUtil.placeOfBirthValidator.test(placeOfBirth);
  }
  static validateDateOfBirth(dateOfBirth: string): boolean {
    const dob = new Date(dateOfBirth);
    const today = new Date();
    const ageDifMs = today.getTime() - dob.getTime();
    const ageDate = new Date(ageDifMs);
    return (
      ageDate.getUTCFullYear() - 1970 > 18 && ageDate.getUTCFullYear() - 1970 < 100 &&
      ValidatorUtil.dateOfBirthValidator.test(dateOfBirth)
    );
  }
  static validateIBAN(input: string): boolean {
    if (!input || typeof input === 'boolean') {
      return false;
    }
    const iban = input.replace(/\s/g, '').toUpperCase();

    const ibanRegex =
      /^(?:(?:IT|SM)\d{2}[A-Z]\d{22}|CY\d{2}[A-Z]\d{23}|NL\d{2}[A-Z]{4}\d{10}|LV\d{2}[A-Z]{4}\d{13}|(?:BG|BH|GB|IE)\d{2}[A-Z]{4}\d{14}|GI\d{2}[A-Z]{4}\d{15}|RO\d{2}[A-Z]{4}\d{16}|KW\d{2}[A-Z]{4}\d{22}|MT\d{2}[A-Z]{4}\d{23}|NO\d{13}|(?:DK|FI|GL|FO)\d{16}|MK\d{17}|(?:AT|EE|KZ|LU|XK)\d{18}|(?:BA|HR|LI|CH|CR)\d{19}|(?:GE|DE|LT|ME|RS)\d{20}|IL\d{21}|(?:AD|CZ|ES|MD|SA)\d{22}|PT\d{23}|(?:BE|IS)\d{24}|(?:FR|MR|MC)\d{25}|(?:AL|DO|LB|PL)\d{26}|(?:AZ|HU)\d{27}|(?:GR|MU)\d{28})$/;
    if (!ibanRegex.test(iban)) {
      return false;
    }

    // Check if the IBAN starts with a valid country code (first two characters)
    const country_code = iban.slice(0, 2);
    const valid_country_codes = [
      'AT',
      'BE',
      'BG',
      'HR',
      'CY',
      'CZ',
      'DK',
      'EE',
      'FI',
      'FR',
      'DE',
      'GR',
      'HU',
      'IE',
      'IT',
      'LV',
      'LT',
      'LU',
      'MT',
      'NL',
      'PL',
      'PT',
      'RO',
      'SK',
      'SI',
      'ES',
      'SE',
    ];

    if (!valid_country_codes.includes(country_code)) {
      return false;
    }

    // Perform IBAN checksum validation
    function customModulo(x: string, y: number) {
      let result = 0;

      for (let i = 0; i < x.length; i++) {
        result = (result * 10 + parseInt(x[i], 10)) % y;
      }

      return result;
    }

    const ibanDigits = (iban.substring(4) + iban.substring(0, 4)).split('');
    let numericIban = '';

    for (let i = 0; i < ibanDigits.length; i++) {
      const char = ibanDigits[i];
      if (char >= 'A' && char <= 'Z') {
        numericIban += (char.charCodeAt(0) - 'A'.charCodeAt(0) + 10).toString();
      } else {
        numericIban += char;
      }
    }

    const remainder = customModulo(numericIban, 97);

    if (remainder !== 1) {
      return false;
    }

    return true;
  }
  static validateRegistrationNumber(registrationNumber: string): boolean {
    return ValidatorUtil.registrationNumberValidator.test(registrationNumber);
  }

  static validateInsuranceOptions = (insuranceOptions: any) => {
    if (insuranceOptions) {
      return true;
    }
  };
  static validateMileageOptions = (mileageOptions: any) => {
    if (mileageOptions) {
      return true;
    }
  };
  static validateOptionalOptions = (optionalOptions: any) => {
    if (optionalOptions) {
      return true;
    }
  };
  static validateRentalInsuranceOptions = (insuranceOptions: any) => {
    if (insuranceOptions) {
      return true;
    }
  };
  static validateRentalMileageOptions = (mileageOptions: any) => {
    if (mileageOptions) {
      return true;
    }
  };
  static validateRentalOptionalOptions = (optionalOptions: any) => {
    if (optionalOptions) {
      return true;
    }
  };
  static validateKassakoopje = (kassaKoopje) => {
    if (kassaKoopje.length > 0) {
      return true;
    }
  };
  static validateCustomer = (
    hasValidFirstName,
    hasValidLastName,
    hasValidPhone,
    hasValidEmail,
    hasValidZipCode,
    hasValidHouseNumber,
    hasValidStreet,
    hasValidDriverLicense,
    hasValidDriverLicenseIssueDate,
    hasValidDriverLicenseExpirationDate,
    hasValidDriverLicenseIssuePlace,
    hasValidPlaceOfBirth,
    hasValidDateOfBirth,
    hasValidIBAN
  ) => {
    if (
      hasValidFirstName &&
      hasValidLastName &&
      hasValidPhone &&
      hasValidEmail &&
      hasValidZipCode &&
      hasValidHouseNumber &&
      hasValidStreet &&
      hasValidDriverLicense &&
      hasValidDriverLicenseIssueDate &&
      hasValidDriverLicenseExpirationDate &&
      hasValidDriverLicenseIssuePlace &&
      hasValidPlaceOfBirth &&
      hasValidDateOfBirth &&
      hasValidIBAN
    ) {
      return true;
    }
  };
  static validateCompany = (
    hasValidCompanyName,
    hasValidRegistrationNumber,
    hasValidPhone,
    hasValidEmail
  ) => {
    if (
      hasValidCompanyName &&
      hasValidPhone &&
      hasValidEmail &&
      hasValidRegistrationNumber
    ) {
      return true;
    }
  };
}
