import { Container } from '@mui/system';
import { Link } from 'gatsby';
import React from 'react';
import Layout from '../components/layout';

const NotFoundPage = () => (
    <Layout>
        <Container>
            <h1>Pagina niet gevonden</h1>
            <Link to='/'>Keer terug naar de Homepagina</Link>
        </Container>
    </Layout>
);

export default NotFoundPage;
