import React, { useEffect, useRef, useState } from 'react';
import ReservationHeader from './shared/reservation-header';
import { Rentvisie, Reservation } from '@bakkie/ratality';
import { navigate } from 'gatsby';
import { ValidatorUtil } from './validator.util';
import { Container } from '@mui/material';
import PaymentPopup from './payment-popup';
import getCustomer from '../../utils/getCustomer';
import convertDateFormat from '../../utils/functons/dateConverter';
import postCustomer from '../../utils/patchCustomer';

declare global {
  interface Window {
    dataLayer: any;
  }
}

Rentvisie.setConfig({
  baseUrl: 'https://api.rentvisie.com/api',
  client: 'ebusje',
  useGuestToken: true,
});

export interface ReservationCheckoutProps {
  // TODO: Add mileagePackageOptions
  reservationData: Reservation;
  query: any;
}

function removeEmptyValues(obj: any) {
  for (const key in obj) {
      if (typeof obj[key] === 'object') {
          removeEmptyValues(obj[key]);
          if (Object.keys(obj[key]).length === 0) {
              delete obj[key];
          }
      } else if (obj[key] === '') {
          delete obj[key];
      }
  }
  return obj;
}

const ReservationCheckout = ({
  reservationData,
  query,
}: ReservationCheckoutProps) => {
  const [showLoading, setShowLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [isSoftLogin, setIsSoftLogin] = useState(false);
  const [email, setEmail] = useState('');
  const [dateOfBirth, setDateOfBirth] = useState('');
  const [registrationNumber, setRegistrationNumber] = useState('');
  const [customerBData, setCustomerBData] = useState<any>({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    address: '',
    company: '',
  });
  const [noFound, setNoFound] = useState(false);
  const [isLogged, setIsLogged] = useState(false);

  let includeVat = 'no';
  if (typeof window !== 'undefined') {
    includeVat = localStorage.getItem('includeVat');
  }

  const reservationReference = reservationData.reservationReference;
  const bookingReference = reservationData.rentals[0].bookingReference;
  const {
    pickupLocation,
    pickupDate,
    pickupTime,
    dropOffDate,
    dropOffTime,
    vehicleClass,
  } = reservationData.rentals[0];
  // const kassaKoopje = vehicleClass.optionalOptions.filter(option => option.category === "Kassakoopje");
  const isEmailValid = ValidatorUtil.validateEmail(email);
  const isDateOfBirthValid = ValidatorUtil.validateDateOfBirth(dateOfBirth);
  const isRegistrationNumberValid =
    ValidatorUtil.validateRegistrationNumber(registrationNumber);

  const softLoginHandler = async () => {
    if (includeVat === 'yes' && !isEmailValid && !isDateOfBirthValid) {
      console.log('error date of birth');
      return;
    }
    if (includeVat === 'no' && !isEmailValid && !isRegistrationNumberValid) {
      console.log('error registration number');
      return;
    }

    let response;

    if (registrationNumber) {
      response = await getCustomer(email, null, registrationNumber);
    } else {
      response = await getCustomer(email, convertDateFormat(dateOfBirth));
    }

    if (response !== undefined) {
      setCustomerBData(response);
    } else {
      setNoFound(true);
    }
    setIsLogged(true);
  };
  const informationHandler = async (a: 'again' | 'first') => {
    if (a === 'first') {
      setIsSoftLogin(false);
    }
    setCustomerBData(null);
    setIsLogged(false);
    setNoFound(false);
  };
  const rentalHasInsuranceOptions =
    ValidatorUtil.validateRentalInsuranceOptions(
      reservationData.rentals[0].insurances[0]
    );
  const rentalHasMileageOptions = ValidatorUtil.validateRentalMileageOptions(
    reservationData.rentals[0].mileagePackages[0]
  );
  const rentalHasOptionalOptions = ValidatorUtil.validateRentalOptionalOptions(
    reservationData.rentals[0].options
  );

  const insuranceOptionsData = [];
  if (rentalHasInsuranceOptions) {
    insuranceOptionsData.push(reservationData.rentals[0].insurances[0]);
  }
  const mileagePackageOptionsData = [];
  if (rentalHasMileageOptions) {
    mileagePackageOptionsData.push(
      reservationData.rentals[0].mileagePackages[0]
    );
  }
  const optionalOptionsData = reservationData.rentals[0].options;
  const [postalcodeData, setPostalcodeData] = useState(undefined);

  const [customerData, setCustomerData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    dateOfBirth: '',
    placeOfBirth: '',
    iban: '',
  });
  const [driversLicense, setDriversLicense] = useState({
    number: '',
    issueDate: '',
    expiryDate: '',
    issuePlace: '',
  });
  const [physicalAddress, setPhysicalAddress] = useState({
    houseNumber: '',
    houseNumberAdditional: '',
    streetName: '',
    city: '',
    postalCode: '',
    countryCode: 'NL',
  });

  const [cellphoneNumber, setcellphoneNumber] = useState({
    countryDialingCode: '31',
    number: '',
  });

  const [companyData, setCompanyData] = useState({
    name: '',
    vatNumber: '',
    registrationNumber: '',
  });
  const [newCustomerData, setNewCustomerData] = useState({});

  //Validations
  const hasValidBusinessName = ValidatorUtil.validateName(companyData.name);
  const hasValidVatNumber = ValidatorUtil.validateVatNumber(
    companyData.vatNumber
  );
  const hasValidRegistrationNumber = ValidatorUtil.validateRegistrationNumber(
    companyData.registrationNumber
  );
  const hasValidFirstName = ValidatorUtil.validateName(customerData.firstName);
  const hasValidLastName = ValidatorUtil.validateName(customerData.lastName);
  const hasValidPhone = ValidatorUtil.validatePhone(cellphoneNumber.number);
  const hasValidEmail = ValidatorUtil.validateEmail(customerData.email);
  const hasValidZipCode = ValidatorUtil.validateZipCode(
    physicalAddress.postalCode
  );
  const hasValidHouseNumber = ValidatorUtil.validateHouseNumber(
    physicalAddress.houseNumber
  );
  const hasValidAddition = ValidatorUtil.validateAddition(
    physicalAddress.houseNumberAdditional
  );
  const hasValidStreet = ValidatorUtil.validateStreet(
    physicalAddress.streetName
  );
  const hasValidDriverLicense = ValidatorUtil.validateDriverLicense(
    driversLicense.number
  );
  const hasValidissueDate = ValidatorUtil.validateDriverLicenseIssueDate(
    driversLicense.issueDate
  );
  const hasValiexpiryDate = ValidatorUtil.validateDriverLicenseExpirationDate(
    driversLicense.expiryDate,
    driversLicense.issueDate
  );
  const hasValidissuePlace = ValidatorUtil.validateDriverLicenseIssuePlace(
    driversLicense.issuePlace
  );

  const hasValidPlaceOfBirth = ValidatorUtil.validatePlaceOfBirth(
    customerData.placeOfBirth
  );
  const hasValidDateOfBirth = ValidatorUtil.validateDateOfBirth(
    customerData.dateOfBirth
  );
  const hasValidIBAN = ValidatorUtil.validateIBAN(customerData.iban);
  const isValidCustomer = ValidatorUtil.validateCustomer(
    hasValidFirstName,
    hasValidLastName,
    hasValidPhone,
    hasValidEmail,
    hasValidZipCode,
    hasValidHouseNumber,
    hasValidStreet,
    hasValidDriverLicense,
    hasValidissueDate,
    hasValiexpiryDate,
    hasValidissuePlace,
    hasValidPlaceOfBirth,
    hasValidDateOfBirth,
    hasValidIBAN
  );
  const isValidCompany = ValidatorUtil.validateCompany(
    hasValidBusinessName,
    hasValidRegistrationNumber,
    hasValidPhone,
    hasValidEmail
  );

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const itemStr = localStorage.getItem('returnToCheckout');
      // if the item doesn't exist, return null
      if (!itemStr) {
        localStorage.removeItem('returnToCheckout');
      }
      const item = {
        reservationReference: reservationReference,
        expiry: reservationData.expiryDate,
      };
      localStorage.setItem('returnToCheckout', JSON.stringify(item));
    }
  }, [reservationData.expiryDate]);

  const customerDataChanged = useRef(false);

  useEffect(() => {
    if (
      physicalAddress.postalCode !== '' &&
      physicalAddress.houseNumber !== ''
    ) {
      // Prevent running and infinite loogp
      const isValidQuery = hasValidHouseNumber && hasValidZipCode;
      if (isValidQuery) {
        //inputAddress into PostalCode API
        const addressQuery = {
          country: 'NL',
          houseNumber: physicalAddress.houseNumber,
          zipCode: physicalAddress.postalCode,
        };

        Rentvisie.getAddress(addressQuery)
          .then((data) => {
            setPhysicalAddress({
              ...physicalAddress,
              streetName: data.street,
            });
            setPostalcodeData(data);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }
  }, [customerDataChanged.current]); // Only run on customerDataChanged

  const handleChange = (e) => {
    setCustomerData({
      ...customerData,
      [e.target.name]: e.target.value,
    });
  };

  const handleChangeDriverLicense = (e) => {
    setDriversLicense({
      ...driversLicense,
      [e.target.name]: e.target.value,
    });
  };

  const handleChangeCompany = (e) => {
    setCompanyData({
      ...companyData,
      [e.target.name]: e.target.value,
    });
  };

  const handleChangePhysicalAddress = (e) => {
    setPhysicalAddress({
      ...physicalAddress,
      [e.target.name]: e.target.value,
    });
    customerDataChanged.current = !customerDataChanged.current;
  };

  const handleChangePhone = (e) => {
    setcellphoneNumber({
      ...cellphoneNumber,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    setNewCustomerData({
      ...customerData,
      dateOfBirth:
        includeVat === 'yes' ? convertDateFormat(customerData.dateOfBirth) : '',
      physicalAddress: {
        ...physicalAddress,
      },
      company: {
        ...companyData,
      },
      driversLicense: {
        ...driversLicense,
        issueDate:
          includeVat === 'yes'
            ? convertDateFormat(driversLicense.issueDate)
            : '',
        expiryDate:
          includeVat === 'yes'
            ? convertDateFormat(driversLicense.expiryDate)
            : '',
      },
      cellphoneNumber: {
        ...cellphoneNumber,
      },
    });
  }, [customerData, physicalAddress, companyData, cellphoneNumber]);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      if (!newCustomerData) {
        localStorage.setItem(
          'storeCustomerData',
          JSON.stringify(newCustomerData)
        );
      }
    }
  }, [newCustomerData]);

  const totalDays = reservationData.rentals[0].days;
  // Update Price
  const getPrice = (basePrice): number => {
    return (parseFloat(basePrice) * 100) / 100;
  };
  const getPriceOption = (price, type) => {
    if (type === 'PerDay') {
      return price * totalDays;
    }
    return price;
  };

  // Update Reservation && Redirect Booking to Thank You Page
  const updateReservation = async (newCustomerData) => {
    try {
      setShowLoading(true);

      if (typeof window !== 'undefined') {
        await localStorage.setItem(
          'reservationReference',
          reservationReference
        );
        const itemStr = await localStorage.getItem('returnToCheckout');
        if (!itemStr) {
          return null;
        }
        await localStorage.removeItem('returnToCheckout');
      }

      const customerData = isSoftLogin
        ? {
            customerId: customerBData.customerId,
          }
        : newCustomerData;

      await postCustomer(reservationReference, bookingReference, removeEmptyValues(customerData));

      await Rentvisie.confirmationEmail(reservationReference, bookingReference);
      const url = `/huren/bedankt?reservationReference=${reservationReference}`;

      navigate(url);
    } catch (e) {
      console.log(e);
    } finally {
      setShowLoading(false);
    }
  };

  const handlePaymentPopup = () => {
    console.log(newCustomerData);
    setOpenModal(true);
  };

  const expiryDate = new Date(reservationData.expiryDate).getTime();
  const calculateTimeLeft = () => {
    const now = new Date().getTime();
    const difference = expiryDate - now;
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        minuten: Math.floor((difference / 1000 / 60) % 60),
        seconden: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);
  });

  const redirect = () => {
    window.location.replace('/');
  };

  if (reservationData.reservationStatus !== 'Draft') {
    navigate('/');
  }

  const [showSummary, setShowSummary] = useState(false);
  return (
    <Container>
      <div>
        <div>
          <ReservationHeader activeStepIndex={3} />
          <div className="reservation-select-wrapper my-l">
            {showLoading ? (
              <h3>
                Ogenblik geduld, wij kloppen uw reservering nu in het systeem.
              </h3>
            ) : (
              <>
                {(!isSoftLogin
                  ? includeVat === 'yes'
                    ? isValidCustomer
                    : isValidCompany
                  : customerBData?.customerId) && (
                  <PaymentPopup
                    reservationReference={reservationReference}
                    bookingReference={bookingReference}
                    customer={
                      isSoftLogin && customerBData
                        ? { customerId: customerBData.customerId }
                        : removeEmptyValues(newCustomerData)
                    }
                    open={openModal}
                    setOpen={setOpenModal}
                  />
                )}

                <div className="reservation-select-left">
                  <div className="checkout-wrapper" style={{ padding: 10 }}>
                    <div className="reservation__title">
                      <h2>Maak jouw reservering compleet</h2>
                      <p style={{ fontStyle: 'italic' }}>
                        <span className="bold">Let op:</span> U heeft nog{' '}
                        {timeLeft.minuten + timeLeft.seconden > 0
                          ? timeLeft.minuten > 0
                            ? `${timeLeft.minuten} minuten en ${timeLeft.seconden} seconden`
                            : `${timeLeft.seconden} seconden`
                          : redirect()}{' '}
                        om de reservering te voltooien. Daarna dient u het
                        reserveringsproces opnieuw te doorlopen.
                      </p>
                      {!isLogged && (
                        <button
                          className="primary-button"
                          onClick={() => setIsSoftLogin(!isSoftLogin)}
                        >
                          {!isSoftLogin
                            ? `Al een keer gehuurd? Klik hier!`
                            : `Nieuwe klant?`}
                        </button>
                      )}
                    </div>
                    {isSoftLogin ? (
                      isLogged ? (
                        <div>
                          {noFound ? (
                            <div>
                              <h3>Geen klant gevonden</h3>
                              <p>
                                Wij hebben geen klant gevonden met de ingevoerde
                                gegevens. Probeer het opnieuw of vul de gegevens
                                handmatig in.
                              </p>
                            </div>
                          ) : (
                            <div>
                              {customerBData.firstName && (
                                <div>
                                  <h1>Welkom</h1>
                                  <h3>Controleer of u het bent</h3>

                                  <p>Voornaam: {customerBData.firstName}</p>

                                  <p>Achternaam: {customerBData.lastName}</p>
                                  <p>E-mail: {customerBData.email}</p>
                                  <p>Telefoon: {customerBData.phone}</p>
                                  <p>
                                    {!dateOfBirth &&
                                      `Bedrijfsnaam: ${customerBData.company}`}
                                  </p>
                                  <p
                                    style={{
                                      fontWeight: 700,
                                      padding: '12px 0',
                                      fontStyle: 'italic',
                                      // borderBottom: '1px dotted #000'
                                    }}
                                    onClick={() => informationHandler('first')}
                                  >
                                    {'>>'} Reserveren zonder inlog
                                  </p>
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                      ) : (
                        <div className="form-row">
                          <div className="form-100">
                            <label htmlFor="email">Email</label>
                            <input
                              type="text"
                              name="email"
                              value={email}
                              onChange={(e) =>
                                setEmail(e.target.value?.toLowerCase())
                              }
                              className={`textOnInput ${
                                isEmailValid && 'valid'
                              } ${email && !isEmailValid && 'not-valid'}`}
                            ></input>
                          </div>
                          {includeVat === 'yes' ? (
                            <div className="form-100">
                              <label htmlFor="dateOfBirth">Geboortedatum</label>
                              <input
                                type="date"
                                name="dateOfBirth"
                                onChange={(e) => setDateOfBirth(e.target.value)}
                                className={`textOnInput ${
                                  isDateOfBirthValid && 'valid'
                                } ${
                                  dateOfBirth &&
                                  !isDateOfBirthValid &&
                                  'not-valid'
                                }`}
                              ></input>
                            </div>
                          ) : (
                            <div className="form-100">
                              <label htmlFor="registrationNumber">
                                KVK-nummer
                              </label>
                              <input
                                type="text"
                                name="registrationNumber"
                                onChange={(e) =>
                                  setRegistrationNumber(e.target.value)
                                }
                                // className="form-input-alt"
                                className={`textOnInput ${
                                  isRegistrationNumberValid && 'valid'
                                } ${
                                  registrationNumber &&
                                  !isRegistrationNumberValid &&
                                  'not-valid'
                                }`}
                              ></input>
                            </div>
                          )}
                        </div>
                      )
                    ) : (
                      <>
                        {includeVat !== 'yes' && (
                          <>
                            <h4>Bedrijfsgegevens</h4>
                            <div className="form-row">
                              <div className="form-100">
                                <div
                                  className={`textOnInput ${
                                    hasValidBusinessName && 'valid'
                                  } ${
                                    companyData.name &&
                                    !hasValidBusinessName &&
                                    'not-valid'
                                  }`}
                                >
                                  <label htmlFor="name">Bedrijfsnaam</label>
                                  <input
                                    type="text"
                                    name="name"
                                    onChange={handleChangeCompany}
                                    className="form-input-alt"
                                  ></input>
                                </div>
                              </div>
                            </div>
                            <div className="form-row">
                              <div className="form-100">
                                <div
                                  className={`textOnInput ${
                                    hasValidVatNumber && 'valid'
                                  } ${
                                    companyData.vatNumber &&
                                    !hasValidVatNumber &&
                                    'not-valid'
                                  }`}
                                >
                                  <label htmlFor="vatNumber">
                                    BTW-nummer (optioneel)
                                  </label>
                                  <input
                                    type="text"
                                    name="vatNumber"
                                    onChange={handleChangeCompany}
                                    className="form-input-alt"
                                  ></input>
                                </div>
                              </div>
                            </div>
                            <div>
                              <div
                                className={`textOnInput ${
                                  hasValidRegistrationNumber && 'valid'
                                } ${
                                  companyData.registrationNumber &&
                                  !hasValidRegistrationNumber &&
                                  'not-valid'
                                }`}
                              >
                                <label htmlFor="registrationNumber">
                                  KvK-nummer
                                </label>
                                <input
                                  type="text"
                                  name="registrationNumber"
                                  onChange={handleChangeCompany}
                                  className="form-input-alt"
                                ></input>
                              </div>
                            </div>
                          </>
                        )}
                        <h4>Contactpersoon</h4>

                        <div className="form-row">
                          <div className="form-50">
                            <div
                              className={`textOnInput ${
                                hasValidFirstName && 'valid'
                              } ${
                                customerData.firstName &&
                                !hasValidFirstName &&
                                'not-valid'
                              }`}
                            >
                              <label htmlFor="firstName">Voornaam</label>
                              <input
                                type="text"
                                name="firstName"
                                onChange={handleChange}
                                className="form-input-alt"
                              ></input>
                            </div>
                          </div>
                          <div className="form-50">
                            <div
                              className={`textOnInput ${
                                hasValidLastName && 'valid'
                              } ${
                                customerData.lastName &&
                                !hasValidLastName &&
                                'not-valid'
                              }`}
                            >
                              <label htmlFor="lastName">Achternaam</label>
                              <input
                                type="text"
                                name="lastName"
                                onChange={handleChange}
                                className="form-input-alt"
                              ></input>
                            </div>
                          </div>
                        </div>

                        <div className="form-row">
                          <div className="form-50">
                            <div
                              className={`textOnInput ${
                                hasValidEmail && 'valid'
                              } ${
                                customerData.email &&
                                !hasValidEmail &&
                                'not-valid'
                              }`}
                            >
                              <label htmlFor="email">Email</label>
                              <input
                                type="email"
                                name="email"
                                onChange={handleChange}
                                className={'form-input-alt'}
                              ></input>
                            </div>
                          </div>
                          <div className="form-50">
                            <div
                              className={`textOnInput ${
                                hasValidPhone && 'valid'
                              } ${
                                cellphoneNumber.number &&
                                !hasValidPhone &&
                                'not-valid'
                              }`}
                            >
                              <label htmlFor="number">Telefoon</label>
                              <input
                                type="phone"
                                name="number"
                                onChange={handleChangePhone}
                                className="form-input-alt"
                              ></input>
                            </div>
                          </div>
                        </div>
                        {includeVat === 'yes' && (
                          <>
                            <div className="form-row">
                              <div className="form-50">
                                <div
                                  className={`textOnInput ${
                                    hasValidPlaceOfBirth && 'valid'
                                  } ${
                                    customerData.placeOfBirth &&
                                    !hasValidPlaceOfBirth &&
                                    'not-valid'
                                  }`}
                                >
                                  <label htmlFor="number">Geboorteplaats</label>
                                  <input
                                    type="text"
                                    name="placeOfBirth"
                                    onChange={handleChange}
                                    className="form-input-alt"
                                  ></input>
                                </div>
                              </div>
                              <div className="form-33">
                                <div
                                  className={`textOnInput ${
                                    hasValidDateOfBirth && 'valid'
                                  } ${
                                    customerData.dateOfBirth &&
                                    !hasValidDateOfBirth &&
                                    'not-valid'
                                  }`}
                                >
                                  <label htmlFor="number">Geboortedatum</label>
                                  <input
                                    type="date"
                                    name="dateOfBirth"
                                    onChange={handleChange}
                                    className="form-input-alt"
                                  ></input>
                                </div>
                              </div>
                            </div>
                            <h4>Adresgegevens</h4>
                            <div className="form-row">
                              <div className="form-33">
                                <div
                                  className={`textOnInput ${
                                    hasValidZipCode && 'valid'
                                  } ${
                                    physicalAddress.postalCode &&
                                    !hasValidZipCode &&
                                    'not-valid'
                                  }`}
                                >
                                  <label htmlFor="postalCode">Postcode</label>
                                  <input
                                    type="text"
                                    name="postalCode"
                                    className="form-input-alt"
                                    id="postalCode"
                                    maxLength={6}
                                    onChange={handleChangePhysicalAddress}
                                  ></input>
                                </div>
                              </div>

                              <div className="form-33">
                                <div
                                  className={`textOnInput ${
                                    hasValidHouseNumber && 'valid'
                                  } ${
                                    physicalAddress.houseNumber &&
                                    !hasValidHouseNumber &&
                                    'not-valid'
                                  }`}
                                >
                                  <label htmlFor="houseNumber">
                                    Huisnummer
                                  </label>
                                  <input
                                    type="text"
                                    name="houseNumber"
                                    className="form-input-alt"
                                    id="houseNumber"
                                    maxLength={6}
                                    onBlur={handleChangePhysicalAddress}
                                  ></input>
                                </div>
                              </div>
                              <div className="form-33">
                                <div
                                  className={`textOnInput ${
                                    hasValidAddition && 'valid'
                                  } ${
                                    physicalAddress.houseNumberAdditional &&
                                    !hasValidAddition &&
                                    'not-valid'
                                  }`}
                                >
                                  <label htmlFor="houseNumberAdditional">
                                    Toevoeging
                                  </label>
                                  <input
                                    type="text"
                                    name="houseNumberAdditional"
                                    onChange={handleChangePhysicalAddress}
                                    className="form-input-alt"
                                  ></input>
                                </div>
                              </div>
                            </div>
                            {postalcodeData && (
                              <div className="form-row">
                                <div className="form-100">
                                  <div
                                    className={`textOnInput ${
                                      hasValidStreet && 'valid'
                                    } ${
                                      (physicalAddress.streetName == null ||
                                        !hasValidStreet) &&
                                      'not-valid'
                                    }`}
                                  >
                                    <label htmlFor="streetName">Straat</label>
                                    <input
                                      type="text"
                                      className="form-input-alt"
                                      name="streetName"
                                      value={`${postalcodeData.street}, ${postalcodeData.city}`}
                                      placeholder="Controleer postcode & huisnummer"
                                      disabled
                                    ></input>
                                  </div>
                                </div>
                              </div>
                            )}
                          </>
                        )}

                        {includeVat === 'yes' && (
                          <>
                            <h4>Rijbewijs</h4>

                            <div className="form-row">
                              <div className="form-50">
                                <div
                                  className={`textOnInput ${
                                    hasValidDriverLicense && 'valid'
                                  } ${
                                    driversLicense.number &&
                                    !hasValidDriverLicense &&
                                    'not-valid'
                                  }`}
                                >
                                  <label htmlFor="number">
                                    Rijbewijs Nummer
                                  </label>
                                  <input
                                    type="text"
                                    name="number"
                                    onChange={handleChangeDriverLicense}
                                    className="form-input-alt"
                                  ></input>
                                </div>
                              </div>
                              <div className="form-50">
                                <div
                                  className={`textOnInput ${
                                    hasValidissuePlace && 'valid'
                                  } ${
                                    driversLicense.issuePlace &&
                                    !hasValidissuePlace &&
                                    'not-valid'
                                  }`}
                                >
                                  <label htmlFor="number">
                                    Rijbewijs Uitgifte plaats
                                  </label>
                                  <input
                                    type="text"
                                    name="issuePlace"
                                    onChange={handleChangeDriverLicense}
                                    className="form-input-alt"
                                  ></input>
                                </div>
                              </div>
                            </div>
                            <div className="form-row">
                              <div className="form-50">
                                <div
                                  className={`textOnInput ${
                                    hasValidissueDate && 'valid'
                                  } ${
                                    driversLicense.issueDate &&
                                    !hasValidissueDate &&
                                    'not-valid'
                                  }`}
                                >
                                  <label htmlFor="number">
                                    Rijbewijs Uitgifte datum
                                  </label>
                                  <input
                                    type="date"
                                    name="issueDate"
                                    onChange={handleChangeDriverLicense}
                                    className="form-input-alt"
                                  ></input>
                                </div>
                              </div>
                              <div className="form-50">
                                <div
                                  className={`textOnInput ${
                                    hasValiexpiryDate && 'valid'
                                  } ${
                                    driversLicense.expiryDate &&
                                    !hasValiexpiryDate &&
                                    'not-valid'
                                  }`}
                                >
                                  <label htmlFor="number">
                                    Rijbewijs Vervaldatum
                                  </label>
                                  <input
                                    type="date"
                                    name="expiryDate"
                                    onChange={handleChangeDriverLicense}
                                    className="form-input-alt"
                                  ></input>
                                </div>
                              </div>
                            </div>

                            <h4>Bankgegevens</h4>
                            <div className="form-row">
                              <div className="form-100">
                                <div
                                  className={`textOnInput ${
                                    hasValidIBAN && 'valid'
                                  } ${
                                    customerData.iban &&
                                    !hasValidIBAN &&
                                    'not-valid'
                                  }`}
                                >
                                  <label htmlFor="number">IBAN</label>
                                  <input
                                    type="text"
                                    name="iban"
                                    onChange={handleChange}
                                    className="form-input-alt"
                                  ></input>
                                  <p style={{ margin: '2px 0', fontSize: 10 }}>
                                    Op dit rekeningnummer storten wij de borg
                                    terug.
                                  </p>
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                      </>
                    )}
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginTop: 24,
                      }}
                    >
                      <div>
                        <button onClick={() => setShowSummary(!showSummary)}>
                          {showSummary ? 'Minimaliseer' : 'Toon Winkelmandje'}
                        </button>
                      </div>
                      {isSoftLogin ? (
                        isLogged && !noFound ? (
                          <div
                            style={{
                              display: 'flex',
                              gap: 10,
                            }}
                          >
                            {includeVat !== 'yes' && (
                              <div>
                                <button
                                  className="primary-button-light"
                                  onClick={() =>
                                    updateReservation(newCustomerData)
                                  }
                                >
                                  Reserveren (betaal later)
                                </button>
                              </div>
                            )}
                            <div>
                              <button
                                className="primary-button"
                                onClick={() => handlePaymentPopup()}
                              >
                                Reserveren (betaal nu)
                              </button>
                            </div>
                          </div>
                        ) : (
                          <div>
                            <button
                              className="primary-button"
                              onClick={
                                noFound
                                  ? () => informationHandler('again')
                                  : () => softLoginHandler()
                              }
                            >
                              {noFound ? 'Probeer het opnieuw' : 'Inloggen'}
                            </button>
                          </div>
                        )
                      ) : (
                        <div>
                          {isValidCustomer || isValidCompany ? (
                            includeVat === 'yes' ? (
                              <button
                                className="primary-button"
                                onClick={() => handlePaymentPopup()}
                              >
                                Reserveren (betaal nu)
                              </button>
                            ) : (
                              <div
                                style={{
                                  display: 'flex',
                                  gap: 10,
                                }}
                              >
                                <button
                                  className="primary-button-light"
                                  onClick={() =>
                                    updateReservation(newCustomerData)
                                  }
                                >
                                  Reserveer (betaal later)
                                </button>
                                <button
                                  className="primary-button"
                                  onClick={() => handlePaymentPopup()}
                                >
                                  Reserveer (betaal nu)
                                </button>
                              </div>
                            )
                          ) : (
                            <button disabled>
                              Vul de gevraagde gegevens in
                            </button>
                          )}
                        </div>
                      )}
                    </div>
                    {showSummary && (
                      <>
                        {/* TODO: Add Payment */}
                        {/* <div className="payment"><h2>Betaling</h2></div> */}
                        <div
                          className="shopping-cart"
                          style={{ marginTop: 20 }}
                        >
                          <h2>Winkelmandje</h2>
                          <span style={{ fontSize: 10 }}>
                            Aantal dag(en): {totalDays}
                          </span>
                          <div className="shopping-cart-wrapper">
                            <div className="shopping-cart-category">
                              <span className="bold">Voertuig</span>
                              <hr></hr>
                              <div className="shopping-cart-row">
                                <div className="shopping-cart-left">
                                  {reservationData.rentals[0].vehicleClass
                                    .labelMarkup
                                    ? reservationData.rentals[0].vehicleClass.labelMarkup?.replace(
                                        /(<([^>]+)>)/gi,
                                        ''
                                      )
                                    : reservationData.rentals[0].vehicleClass
                                        .description}
                                </div>
                                <div className="shopping-cart-right">
                                  €{' '}
                                  {(
                                    (reservationData.rentals[0].price.value *
                                      totalDays) /
                                    1.21
                                  )
                                    .toFixed(2)
                                    .toString()
                                    .replace('.', ',')}
                                </div>
                              </div>
                            </div>
                            {rentalHasInsuranceOptions && (
                              <div className="shopping-cart-category">
                                <span className="bold">
                                  Verzekering & Eigen Risico
                                </span>
                                <hr></hr>
                                <div className="shopping-cart-row">
                                  <div className="shopping-cart-left">
                                    {insuranceOptionsData[0].description}
                                  </div>
                                  <div className="shopping-cart-right">
                                    €{' '}
                                    {(
                                      getPriceOption(
                                        insuranceOptionsData[0].price.value,

                                        insuranceOptionsData[0].price.unitType
                                      ) / 1.21
                                    )
                                      .toFixed(2)
                                      .toString()
                                      .replace('.', ',')}
                                  </div>
                                </div>
                              </div>
                            )}
                            {rentalHasMileageOptions && (
                              <div className="shopping-cart-category">
                                <span className="bold">Kilometers</span>
                                <hr></hr>
                                <div className="shopping-cart-row">
                                  <div className="shopping-cart-left">
                                    {mileagePackageOptionsData[0].description}
                                  </div>
                                  <div className="shopping-cart-right">
                                    €{' '}
                                    {(
                                      getPriceOption(
                                        mileagePackageOptionsData[0].price
                                          .value,
                                        mileagePackageOptionsData[0].price
                                          .unitType
                                      ) / 1.21
                                    )
                                      .toFixed(2)
                                      .toString()
                                      .replace('.', ',')}
                                  </div>
                                </div>
                              </div>
                            )}
                            {rentalHasOptionalOptions && (
                              <div className="shopping-cart-category">
                                <span className="bold">Overige</span>
                                <hr></hr>
                                {optionalOptionsData.map((option) => (
                                  <div
                                    key={option.optionId}
                                    className="shopping-cart-row"
                                  >
                                    <div className="shopping-cart-left">
                                      {option.description}
                                    </div>
                                    <div className="shopping-cart-right">
                                      €{' '}
                                      {(
                                        getPriceOption(
                                          option.price.value,
                                          option.price.unitType
                                        ) / 1.21
                                      )
                                        .toFixed(2)
                                        .toString()
                                        .replace('.', ',')}
                                    </div>
                                  </div>
                                ))}
                              </div>
                            )}
                            {/* TODO: CHECK FOR MULTIPLE DAYS */}
                            <div className="shopping-cart-category">
                              <span className="bold">Totaal</span>
                              <hr></hr>
                              <div className="shopping-cart-row">
                                <div className="shopping-cart-left">
                                  Totaal (excl. BTW)
                                </div>
                                <div className="shopping-cart-right">
                                  {`€ ${' '} ${(
                                    getPrice(
                                      reservationData.reservationTotal.value
                                    ) / 1.21
                                  )
                                    .toFixed(2)
                                    .toString()
                                    .replace('.', ',')}`}
                                </div>
                              </div>
                              {includeVat === 'yes' && (
                                <div className="shopping-cart-row">
                                  <div className="shopping-cart-left">BTW</div>
                                  <div className="shopping-cart-right">
                                    {`€ ${' '} ${(
                                      (getPrice(
                                        reservationData.reservationTotal.value
                                      ) /
                                        1.21) *
                                      0.21
                                    )
                                      .toFixed(2)
                                      .toString()
                                      .replace('.', ',')}`}
                                  </div>
                                </div>
                              )}
                              {includeVat === 'yes' && (
                                <div className="shopping-cart-row bold">
                                  <div className="shopping-cart-left">
                                    Totaal (incl.)
                                  </div>
                                  <div className="shopping-cart-right">
                                    {`€ ${' '} ${getPrice(
                                      reservationData.reservationTotal.value
                                    )
                                      .toFixed(2)
                                      .toString()
                                      .replace('.', ',')}`}
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
                <div className="reservation-select-right">
                  <div className="reservation-contact">
                    <div className="reservation-product__photo">
                      <img
                        src={vehicleClass.images[0].imageUrl}
                        className="img-fluid rounded"
                        alt={vehicleClass.images[0].fileName}
                        title={vehicleClass.description}
                      />
                    </div>
                    <div className="reservation-product__text">
                      <p>
                        <a
                          href={encodeURI(
                            `https://www.google.com/maps/search/?api=1&query=${pickupLocation.streetAddress}`
                          )}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {pickupLocation.streetAddress}
                        </a>
                      </p>
                      <p>
                        Van: {pickupDate} {pickupTime} <br></br>
                        Tot: {dropOffDate} {dropOffTime} <br></br>
                      </p>
                      <h3 className="bold">
                        {reservationData.rentals[0].vehicleClass.labelMarkup
                          ? reservationData.rentals[0].vehicleClass.labelMarkup?.replace(
                              /(<([^>]+)>)/gi,
                              ''
                            )
                          : reservationData.rentals[0].vehicleClass.description}
                      </h3>
                      <h3 className="extrabox__price">
                        {includeVat === 'yes'
                          ? `€ ${' '} ${(
                              (getPrice(
                                reservationData.reservationTotal.value
                              ) *
                                100) /
                              100
                            )
                              .toFixed(2)
                              .toString()
                              .replace('.', ',')}`
                          : `€ ${' '} ${(
                              getPrice(reservationData.reservationTotal.value) /
                              1.21
                            )
                              .toFixed(2)
                              .toString()
                              .replace('.', ',')}`}
                        {includeVat === 'yes' ? ' incl. BTW' : ' excl. BTW'}
                      </h3>
                      Borg: € 350
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </Container>
  );
};

export default ReservationCheckout;
