import { Container } from "@mui/system";
import reactMarkdown from "react-markdown";
import MissionImage from '../../images/kuperus-ebusje-mission.webp';
import ReactMarkDown from 'react-markdown';

const OurMission = ({ mission }) => (
    <section>
        <Container>
            <div className='row-50'>
                <div>
                    <img src={mission.image.url} alt='missie' className="image-bg-secondary-light" style={{ maxWidth: '100%' }}></img>
                </div>
                <div>
                    <ReactMarkDown>{mission.body.data.body}</ReactMarkDown>
                </div>
            </div>
        </Container>
    </section>
)

export default OurMission; 