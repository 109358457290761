import axios from 'axios';
export default async function getCustomer(
  email: string,
  dateOfBirth?: string,
  registrationNumber?: string
) {
  console.log(dateOfBirth);
  const url = `https://api.eu.production.rentvisie.com/api/v2/customers/rv?email=${email}${
    dateOfBirth ? `&dateOfBirth=${dateOfBirth}` : ''
  }${registrationNumber ? `&registrationNumber=${registrationNumber}` : ''}`;
  try {
    const response = await axios.get(url, {
      headers: {
        clientid: 420488,
        channelid: 5087,
      },
    });
    console.log('r', response);
    return response.data;
  } catch (error) {
    console.error(error);
  }
}
